import React, {useState, useEffect} from 'react';
import {Pagination} from 'react-bootstrap';
import {Link} from "react-router-dom";
import '../style/scss/Account.scss';
import "../style/scss/pagination.scss";
import {Dropdown} from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import i18n from "../i18n";

import api from "../helpers/axios";
import Loading from "./Loading/Loading";

function dots() {
    return (
        <svg width="138" height="138" viewBox="0 0 138 138" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 0 1.5 0C0.671573 0 0 0.671573 0 1.5C0 2.32843 0.671573 3 1.5 3Z"
                fill="#EFF3FD"/>
            <path
                d="M16.5 3C17.3284 3 18 2.32843 18 1.5C18 0.671573 17.3284 0 16.5 0C15.6716 0 15 0.671573 15 1.5C15 2.32843 15.6716 3 16.5 3Z"
                fill="#EFF3FD"/>
            <path
                d="M31.5 3C32.3284 3 33 2.32843 33 1.5C33 0.671573 32.3284 0 31.5 0C30.6716 0 30 0.671573 30 1.5C30 2.32843 30.6716 3 31.5 3Z"
                fill="#EFF3FD"/>
            <path
                d="M46.5 3C47.3284 3 48 2.32843 48 1.5C48 0.671573 47.3284 0 46.5 0C45.6716 0 45 0.671573 45 1.5C45 2.32843 45.6716 3 46.5 3Z"
                fill="#EFF3FD"/>
            <path
                d="M61.5 3C62.3284 3 63 2.32843 63 1.5C63 0.671573 62.3284 0 61.5 0C60.6716 0 60 0.671573 60 1.5C60 2.32843 60.6716 3 61.5 3Z"
                fill="#EFF3FD"/>
            <path
                d="M76.5 3C77.3284 3 78 2.32843 78 1.5C78 0.671573 77.3284 0 76.5 0C75.6716 0 75 0.671573 75 1.5C75 2.32843 75.6716 3 76.5 3Z"
                fill="#EFF3FD"/>
            <path
                d="M91.5 3C92.3284 3 93 2.32843 93 1.5C93 0.671573 92.3284 0 91.5 0C90.6716 0 90 0.671573 90 1.5C90 2.32843 90.6716 3 91.5 3Z"
                fill="#EFF3FD"/>
            <path
                d="M106.5 3C107.328 3 108 2.32843 108 1.5C108 0.671573 107.328 0 106.5 0C105.672 0 105 0.671573 105 1.5C105 2.32843 105.672 3 106.5 3Z"
                fill="#EFF3FD"/>
            <path
                d="M121.5 3C122.328 3 123 2.32843 123 1.5C123 0.671573 122.328 0 121.5 0C120.672 0 120 0.671573 120 1.5C120 2.32843 120.672 3 121.5 3Z"
                fill="#EFF3FD"/>
            <path
                d="M136.5 3C137.328 3 138 2.32843 138 1.5C138 0.671573 137.328 0 136.5 0C135.672 0 135 0.671573 135 1.5C135 2.32843 135.672 3 136.5 3Z"
                fill="#EFF3FD"/>
            <path
                d="M1.5 18C2.32843 18 3 17.3284 3 16.5C3 15.6716 2.32843 15 1.5 15C0.671573 15 0 15.6716 0 16.5C0 17.3284 0.671573 18 1.5 18Z"
                fill="#EFF3FD"/>
            <path
                d="M16.5 18C17.3284 18 18 17.3284 18 16.5C18 15.6716 17.3284 15 16.5 15C15.6716 15 15 15.6716 15 16.5C15 17.3284 15.6716 18 16.5 18Z"
                fill="#EFF3FD"/>
            <path
                d="M31.5 18C32.3284 18 33 17.3284 33 16.5C33 15.6716 32.3284 15 31.5 15C30.6716 15 30 15.6716 30 16.5C30 17.3284 30.6716 18 31.5 18Z"
                fill="#EFF3FD"/>
            <path
                d="M46.5 18C47.3284 18 48 17.3284 48 16.5C48 15.6716 47.3284 15 46.5 15C45.6716 15 45 15.6716 45 16.5C45 17.3284 45.6716 18 46.5 18Z"
                fill="#EFF3FD"/>
            <path
                d="M61.5 18C62.3284 18 63 17.3284 63 16.5C63 15.6716 62.3284 15 61.5 15C60.6716 15 60 15.6716 60 16.5C60 17.3284 60.6716 18 61.5 18Z"
                fill="#EFF3FD"/>
            <path
                d="M76.5 18C77.3284 18 78 17.3284 78 16.5C78 15.6716 77.3284 15 76.5 15C75.6716 15 75 15.6716 75 16.5C75 17.3284 75.6716 18 76.5 18Z"
                fill="#EFF3FD"/>
            <path
                d="M91.5 18C92.3284 18 93 17.3284 93 16.5C93 15.6716 92.3284 15 91.5 15C90.6716 15 90 15.6716 90 16.5C90 17.3284 90.6716 18 91.5 18Z"
                fill="#EFF3FD"/>
            <path
                d="M106.5 18C107.328 18 108 17.3284 108 16.5C108 15.6716 107.328 15 106.5 15C105.672 15 105 15.6716 105 16.5C105 17.3284 105.672 18 106.5 18Z"
                fill="#EFF3FD"/>
            <path
                d="M121.5 18C122.328 18 123 17.3284 123 16.5C123 15.6716 122.328 15 121.5 15C120.672 15 120 15.6716 120 16.5C120 17.3284 120.672 18 121.5 18Z"
                fill="#EFF3FD"/>
            <path
                d="M136.5 18C137.328 18 138 17.3284 138 16.5C138 15.6716 137.328 15 136.5 15C135.672 15 135 15.6716 135 16.5C135 17.3284 135.672 18 136.5 18Z"
                fill="#EFF3FD"/>
            <path
                d="M1.5 33C2.32843 33 3 32.3284 3 31.5C3 30.6716 2.32843 30 1.5 30C0.671573 30 0 30.6716 0 31.5C0 32.3284 0.671573 33 1.5 33Z"
                fill="#EFF3FD"/>
            <path
                d="M16.5 33C17.3284 33 18 32.3284 18 31.5C18 30.6716 17.3284 30 16.5 30C15.6716 30 15 30.6716 15 31.5C15 32.3284 15.6716 33 16.5 33Z"
                fill="#EFF3FD"/>
            <path
                d="M31.5 33C32.3284 33 33 32.3284 33 31.5C33 30.6716 32.3284 30 31.5 30C30.6716 30 30 30.6716 30 31.5C30 32.3284 30.6716 33 31.5 33Z"
                fill="#EFF3FD"/>
            <path
                d="M46.5 33C47.3284 33 48 32.3284 48 31.5C48 30.6716 47.3284 30 46.5 30C45.6716 30 45 30.6716 45 31.5C45 32.3284 45.6716 33 46.5 33Z"
                fill="#EFF3FD"/>
            <path
                d="M61.5 33C62.3284 33 63 32.3284 63 31.5C63 30.6716 62.3284 30 61.5 30C60.6716 30 60 30.6716 60 31.5C60 32.3284 60.6716 33 61.5 33Z"
                fill="#EFF3FD"/>
            <path
                d="M76.5 33C77.3284 33 78 32.3284 78 31.5C78 30.6716 77.3284 30 76.5 30C75.6716 30 75 30.6716 75 31.5C75 32.3284 75.6716 33 76.5 33Z"
                fill="#EFF3FD"/>
            <path
                d="M91.5 33C92.3284 33 93 32.3284 93 31.5C93 30.6716 92.3284 30 91.5 30C90.6716 30 90 30.6716 90 31.5C90 32.3284 90.6716 33 91.5 33Z"
                fill="#EFF3FD"/>
            <path
                d="M106.5 33C107.328 33 108 32.3284 108 31.5C108 30.6716 107.328 30 106.5 30C105.672 30 105 30.6716 105 31.5C105 32.3284 105.672 33 106.5 33Z"
                fill="#EFF3FD"/>
            <path
                d="M121.5 33C122.328 33 123 32.3284 123 31.5C123 30.6716 122.328 30 121.5 30C120.672 30 120 30.6716 120 31.5C120 32.3284 120.672 33 121.5 33Z"
                fill="#EFF3FD"/>
            <path
                d="M136.5 33C137.328 33 138 32.3284 138 31.5C138 30.6716 137.328 30 136.5 30C135.672 30 135 30.6716 135 31.5C135 32.3284 135.672 33 136.5 33Z"
                fill="#EFF3FD"/>
            <path
                d="M1.5 48C2.32843 48 3 47.3284 3 46.5C3 45.6716 2.32843 45 1.5 45C0.671573 45 0 45.6716 0 46.5C0 47.3284 0.671573 48 1.5 48Z"
                fill="#EFF3FD"/>
            <path
                d="M16.5 48C17.3284 48 18 47.3284 18 46.5C18 45.6716 17.3284 45 16.5 45C15.6716 45 15 45.6716 15 46.5C15 47.3284 15.6716 48 16.5 48Z"
                fill="#EFF3FD"/>
            <path
                d="M31.5 48C32.3284 48 33 47.3284 33 46.5C33 45.6716 32.3284 45 31.5 45C30.6716 45 30 45.6716 30 46.5C30 47.3284 30.6716 48 31.5 48Z"
                fill="#EFF3FD"/>
            <path
                d="M46.5 48C47.3284 48 48 47.3284 48 46.5C48 45.6716 47.3284 45 46.5 45C45.6716 45 45 45.6716 45 46.5C45 47.3284 45.6716 48 46.5 48Z"
                fill="#EFF3FD"/>
            <path
                d="M61.5 48C62.3284 48 63 47.3284 63 46.5C63 45.6716 62.3284 45 61.5 45C60.6716 45 60 45.6716 60 46.5C60 47.3284 60.6716 48 61.5 48Z"
                fill="#EFF3FD"/>
            <path
                d="M76.5 48C77.3284 48 78 47.3284 78 46.5C78 45.6716 77.3284 45 76.5 45C75.6716 45 75 45.6716 75 46.5C75 47.3284 75.6716 48 76.5 48Z"
                fill="#EFF3FD"/>
            <path
                d="M91.5 48C92.3284 48 93 47.3284 93 46.5C93 45.6716 92.3284 45 91.5 45C90.6716 45 90 45.6716 90 46.5C90 47.3284 90.6716 48 91.5 48Z"
                fill="#EFF3FD"/>
            <path
                d="M106.5 48C107.328 48 108 47.3284 108 46.5C108 45.6716 107.328 45 106.5 45C105.672 45 105 45.6716 105 46.5C105 47.3284 105.672 48 106.5 48Z"
                fill="#EFF3FD"/>
            <path
                d="M121.5 48C122.328 48 123 47.3284 123 46.5C123 45.6716 122.328 45 121.5 45C120.672 45 120 45.6716 120 46.5C120 47.3284 120.672 48 121.5 48Z"
                fill="#EFF3FD"/>
            <path
                d="M136.5 48C137.328 48 138 47.3284 138 46.5C138 45.6716 137.328 45 136.5 45C135.672 45 135 45.6716 135 46.5C135 47.3284 135.672 48 136.5 48Z"
                fill="#EFF3FD"/>
            <path
                d="M1.5 63C2.32843 63 3 62.3284 3 61.5C3 60.6716 2.32843 60 1.5 60C0.671573 60 0 60.6716 0 61.5C0 62.3284 0.671573 63 1.5 63Z"
                fill="#EFF3FD"/>
            <path
                d="M16.5 63C17.3284 63 18 62.3284 18 61.5C18 60.6716 17.3284 60 16.5 60C15.6716 60 15 60.6716 15 61.5C15 62.3284 15.6716 63 16.5 63Z"
                fill="#EFF3FD"/>
            <path
                d="M31.5 63C32.3284 63 33 62.3284 33 61.5C33 60.6716 32.3284 60 31.5 60C30.6716 60 30 60.6716 30 61.5C30 62.3284 30.6716 63 31.5 63Z"
                fill="#EFF3FD"/>
            <path
                d="M46.5 63C47.3284 63 48 62.3284 48 61.5C48 60.6716 47.3284 60 46.5 60C45.6716 60 45 60.6716 45 61.5C45 62.3284 45.6716 63 46.5 63Z"
                fill="#EFF3FD"/>
            <path
                d="M61.5 63C62.3284 63 63 62.3284 63 61.5C63 60.6716 62.3284 60 61.5 60C60.6716 60 60 60.6716 60 61.5C60 62.3284 60.6716 63 61.5 63Z"
                fill="#EFF3FD"/>
            <path
                d="M76.5 63C77.3284 63 78 62.3284 78 61.5C78 60.6716 77.3284 60 76.5 60C75.6716 60 75 60.6716 75 61.5C75 62.3284 75.6716 63 76.5 63Z"
                fill="#EFF3FD"/>
            <path
                d="M91.5 63C92.3284 63 93 62.3284 93 61.5C93 60.6716 92.3284 60 91.5 60C90.6716 60 90 60.6716 90 61.5C90 62.3284 90.6716 63 91.5 63Z"
                fill="#EFF3FD"/>
            <path
                d="M106.5 63C107.328 63 108 62.3284 108 61.5C108 60.6716 107.328 60 106.5 60C105.672 60 105 60.6716 105 61.5C105 62.3284 105.672 63 106.5 63Z"
                fill="#EFF3FD"/>
            <path
                d="M121.5 63C122.328 63 123 62.3284 123 61.5C123 60.6716 122.328 60 121.5 60C120.672 60 120 60.6716 120 61.5C120 62.3284 120.672 63 121.5 63Z"
                fill="#EFF3FD"/>
            <path
                d="M136.5 63C137.328 63 138 62.3284 138 61.5C138 60.6716 137.328 60 136.5 60C135.672 60 135 60.6716 135 61.5C135 62.3284 135.672 63 136.5 63Z"
                fill="#EFF3FD"/>
            <path
                d="M1.5 78C2.32843 78 3 77.3284 3 76.5C3 75.6716 2.32843 75 1.5 75C0.671573 75 0 75.6716 0 76.5C0 77.3284 0.671573 78 1.5 78Z"
                fill="#EFF3FD"/>
            <path
                d="M16.5 78C17.3284 78 18 77.3284 18 76.5C18 75.6716 17.3284 75 16.5 75C15.6716 75 15 75.6716 15 76.5C15 77.3284 15.6716 78 16.5 78Z"
                fill="#EFF3FD"/>
            <path
                d="M31.5 78C32.3284 78 33 77.3284 33 76.5C33 75.6716 32.3284 75 31.5 75C30.6716 75 30 75.6716 30 76.5C30 77.3284 30.6716 78 31.5 78Z"
                fill="#EFF3FD"/>
            <path
                d="M46.5 78C47.3284 78 48 77.3284 48 76.5C48 75.6716 47.3284 75 46.5 75C45.6716 75 45 75.6716 45 76.5C45 77.3284 45.6716 78 46.5 78Z"
                fill="#EFF3FD"/>
            <path
                d="M61.5 78C62.3284 78 63 77.3284 63 76.5C63 75.6716 62.3284 75 61.5 75C60.6716 75 60 75.6716 60 76.5C60 77.3284 60.6716 78 61.5 78Z"
                fill="#EFF3FD"/>
            <path
                d="M76.5 78C77.3284 78 78 77.3284 78 76.5C78 75.6716 77.3284 75 76.5 75C75.6716 75 75 75.6716 75 76.5C75 77.3284 75.6716 78 76.5 78Z"
                fill="#EFF3FD"/>
            <path
                d="M91.5 78C92.3284 78 93 77.3284 93 76.5C93 75.6716 92.3284 75 91.5 75C90.6716 75 90 75.6716 90 76.5C90 77.3284 90.6716 78 91.5 78Z"
                fill="#EFF3FD"/>
            <path
                d="M106.5 78C107.328 78 108 77.3284 108 76.5C108 75.6716 107.328 75 106.5 75C105.672 75 105 75.6716 105 76.5C105 77.3284 105.672 78 106.5 78Z"
                fill="#EFF3FD"/>
            <path
                d="M121.5 78C122.328 78 123 77.3284 123 76.5C123 75.6716 122.328 75 121.5 75C120.672 75 120 75.6716 120 76.5C120 77.3284 120.672 78 121.5 78Z"
                fill="#EFF3FD"/>
            <path
                d="M136.5 78C137.328 78 138 77.3284 138 76.5C138 75.6716 137.328 75 136.5 75C135.672 75 135 75.6716 135 76.5C135 77.3284 135.672 78 136.5 78Z"
                fill="#EFF3FD"/>
            <path
                d="M1.5 93C2.32843 93 3 92.3284 3 91.5C3 90.6716 2.32843 90 1.5 90C0.671573 90 0 90.6716 0 91.5C0 92.3284 0.671573 93 1.5 93Z"
                fill="#EFF3FD"/>
            <path
                d="M16.5 93C17.3284 93 18 92.3284 18 91.5C18 90.6716 17.3284 90 16.5 90C15.6716 90 15 90.6716 15 91.5C15 92.3284 15.6716 93 16.5 93Z"
                fill="#EFF3FD"/>
            <path
                d="M31.5 93C32.3284 93 33 92.3284 33 91.5C33 90.6716 32.3284 90 31.5 90C30.6716 90 30 90.6716 30 91.5C30 92.3284 30.6716 93 31.5 93Z"
                fill="#EFF3FD"/>
            <path
                d="M46.5 93C47.3284 93 48 92.3284 48 91.5C48 90.6716 47.3284 90 46.5 90C45.6716 90 45 90.6716 45 91.5C45 92.3284 45.6716 93 46.5 93Z"
                fill="#EFF3FD"/>
            <path
                d="M61.5 93C62.3284 93 63 92.3284 63 91.5C63 90.6716 62.3284 90 61.5 90C60.6716 90 60 90.6716 60 91.5C60 92.3284 60.6716 93 61.5 93Z"
                fill="#EFF3FD"/>
            <path
                d="M76.5 93C77.3284 93 78 92.3284 78 91.5C78 90.6716 77.3284 90 76.5 90C75.6716 90 75 90.6716 75 91.5C75 92.3284 75.6716 93 76.5 93Z"
                fill="#EFF3FD"/>
            <path
                d="M91.5 93C92.3284 93 93 92.3284 93 91.5C93 90.6716 92.3284 90 91.5 90C90.6716 90 90 90.6716 90 91.5C90 92.3284 90.6716 93 91.5 93Z"
                fill="#EFF3FD"/>
            <path
                d="M106.5 93C107.328 93 108 92.3284 108 91.5C108 90.6716 107.328 90 106.5 90C105.672 90 105 90.6716 105 91.5C105 92.3284 105.672 93 106.5 93Z"
                fill="#EFF3FD"/>
            <path
                d="M121.5 93C122.328 93 123 92.3284 123 91.5C123 90.6716 122.328 90 121.5 90C120.672 90 120 90.6716 120 91.5C120 92.3284 120.672 93 121.5 93Z"
                fill="#EFF3FD"/>
            <path
                d="M136.5 93C137.328 93 138 92.3284 138 91.5C138 90.6716 137.328 90 136.5 90C135.672 90 135 90.6716 135 91.5C135 92.3284 135.672 93 136.5 93Z"
                fill="#EFF3FD"/>
            <path
                d="M1.5 108C2.32843 108 3 107.328 3 106.5C3 105.672 2.32843 105 1.5 105C0.671573 105 0 105.672 0 106.5C0 107.328 0.671573 108 1.5 108Z"
                fill="#EFF3FD"/>
            <path
                d="M16.5 108C17.3284 108 18 107.328 18 106.5C18 105.672 17.3284 105 16.5 105C15.6716 105 15 105.672 15 106.5C15 107.328 15.6716 108 16.5 108Z"
                fill="#EFF3FD"/>
            <path
                d="M31.5 108C32.3284 108 33 107.328 33 106.5C33 105.672 32.3284 105 31.5 105C30.6716 105 30 105.672 30 106.5C30 107.328 30.6716 108 31.5 108Z"
                fill="#EFF3FD"/>
            <path
                d="M46.5 108C47.3284 108 48 107.328 48 106.5C48 105.672 47.3284 105 46.5 105C45.6716 105 45 105.672 45 106.5C45 107.328 45.6716 108 46.5 108Z"
                fill="#EFF3FD"/>
            <path
                d="M61.5 108C62.3284 108 63 107.328 63 106.5C63 105.672 62.3284 105 61.5 105C60.6716 105 60 105.672 60 106.5C60 107.328 60.6716 108 61.5 108Z"
                fill="#EFF3FD"/>
            <path
                d="M76.5 108C77.3284 108 78 107.328 78 106.5C78 105.672 77.3284 105 76.5 105C75.6716 105 75 105.672 75 106.5C75 107.328 75.6716 108 76.5 108Z"
                fill="#EFF3FD"/>
            <path
                d="M91.5 108C92.3284 108 93 107.328 93 106.5C93 105.672 92.3284 105 91.5 105C90.6716 105 90 105.672 90 106.5C90 107.328 90.6716 108 91.5 108Z"
                fill="#EFF3FD"/>
            <path
                d="M106.5 108C107.328 108 108 107.328 108 106.5C108 105.672 107.328 105 106.5 105C105.672 105 105 105.672 105 106.5C105 107.328 105.672 108 106.5 108Z"
                fill="#EFF3FD"/>
            <path
                d="M121.5 108C122.328 108 123 107.328 123 106.5C123 105.672 122.328 105 121.5 105C120.672 105 120 105.672 120 106.5C120 107.328 120.672 108 121.5 108Z"
                fill="#EFF3FD"/>
            <path
                d="M136.5 108C137.328 108 138 107.328 138 106.5C138 105.672 137.328 105 136.5 105C135.672 105 135 105.672 135 106.5C135 107.328 135.672 108 136.5 108Z"
                fill="#EFF3FD"/>
            <path
                d="M1.5 123C2.32843 123 3 122.328 3 121.5C3 120.672 2.32843 120 1.5 120C0.671573 120 0 120.672 0 121.5C0 122.328 0.671573 123 1.5 123Z"
                fill="#EFF3FD"/>
            <path
                d="M16.5 123C17.3284 123 18 122.328 18 121.5C18 120.672 17.3284 120 16.5 120C15.6716 120 15 120.672 15 121.5C15 122.328 15.6716 123 16.5 123Z"
                fill="#EFF3FD"/>
            <path
                d="M31.5 123C32.3284 123 33 122.328 33 121.5C33 120.672 32.3284 120 31.5 120C30.6716 120 30 120.672 30 121.5C30 122.328 30.6716 123 31.5 123Z"
                fill="#EFF3FD"/>
            <path
                d="M46.5 123C47.3284 123 48 122.328 48 121.5C48 120.672 47.3284 120 46.5 120C45.6716 120 45 120.672 45 121.5C45 122.328 45.6716 123 46.5 123Z"
                fill="#EFF3FD"/>
            <path
                d="M61.5 123C62.3284 123 63 122.328 63 121.5C63 120.672 62.3284 120 61.5 120C60.6716 120 60 120.672 60 121.5C60 122.328 60.6716 123 61.5 123Z"
                fill="#EFF3FD"/>
            <path
                d="M76.5 123C77.3284 123 78 122.328 78 121.5C78 120.672 77.3284 120 76.5 120C75.6716 120 75 120.672 75 121.5C75 122.328 75.6716 123 76.5 123Z"
                fill="#EFF3FD"/>
            <path
                d="M91.5 123C92.3284 123 93 122.328 93 121.5C93 120.672 92.3284 120 91.5 120C90.6716 120 90 120.672 90 121.5C90 122.328 90.6716 123 91.5 123Z"
                fill="#EFF3FD"/>
            <path
                d="M106.5 123C107.328 123 108 122.328 108 121.5C108 120.672 107.328 120 106.5 120C105.672 120 105 120.672 105 121.5C105 122.328 105.672 123 106.5 123Z"
                fill="#EFF3FD"/>
            <path
                d="M121.5 123C122.328 123 123 122.328 123 121.5C123 120.672 122.328 120 121.5 120C120.672 120 120 120.672 120 121.5C120 122.328 120.672 123 121.5 123Z"
                fill="#EFF3FD"/>
            <path
                d="M136.5 123C137.328 123 138 122.328 138 121.5C138 120.672 137.328 120 136.5 120C135.672 120 135 120.672 135 121.5C135 122.328 135.672 123 136.5 123Z"
                fill="#EFF3FD"/>
            <path
                d="M1.5 138C2.32843 138 3 137.328 3 136.5C3 135.672 2.32843 135 1.5 135C0.671573 135 0 135.672 0 136.5C0 137.328 0.671573 138 1.5 138Z"
                fill="#EFF3FD"/>
            <path
                d="M16.5 138C17.3284 138 18 137.328 18 136.5C18 135.672 17.3284 135 16.5 135C15.6716 135 15 135.672 15 136.5C15 137.328 15.6716 138 16.5 138Z"
                fill="#EFF3FD"/>
            <path
                d="M31.5 138C32.3284 138 33 137.328 33 136.5C33 135.672 32.3284 135 31.5 135C30.6716 135 30 135.672 30 136.5C30 137.328 30.6716 138 31.5 138Z"
                fill="#EFF3FD"/>
            <path
                d="M46.5 138C47.3284 138 48 137.328 48 136.5C48 135.672 47.3284 135 46.5 135C45.6716 135 45 135.672 45 136.5C45 137.328 45.6716 138 46.5 138Z"
                fill="#EFF3FD"/>
            <path
                d="M61.5 138C62.3284 138 63 137.328 63 136.5C63 135.672 62.3284 135 61.5 135C60.6716 135 60 135.672 60 136.5C60 137.328 60.6716 138 61.5 138Z"
                fill="#EFF3FD"/>
            <path
                d="M76.5 138C77.3284 138 78 137.328 78 136.5C78 135.672 77.3284 135 76.5 135C75.6716 135 75 135.672 75 136.5C75 137.328 75.6716 138 76.5 138Z"
                fill="#EFF3FD"/>
            <path
                d="M91.5 138C92.3284 138 93 137.328 93 136.5C93 135.672 92.3284 135 91.5 135C90.6716 135 90 135.672 90 136.5C90 137.328 90.6716 138 91.5 138Z"
                fill="#EFF3FD"/>
            <path
                d="M106.5 138C107.328 138 108 137.328 108 136.5C108 135.672 107.328 135 106.5 135C105.672 135 105 135.672 105 136.5C105 137.328 105.672 138 106.5 138Z"
                fill="#EFF3FD"/>
            <path
                d="M121.5 138C122.328 138 123 137.328 123 136.5C123 135.672 122.328 135 121.5 135C120.672 135 120 135.672 120 136.5C120 137.328 120.672 138 121.5 138Z"
                fill="#EFF3FD"/>
            <path
                d="M136.5 138C137.328 138 138 137.328 138 136.5C138 135.672 137.328 135 136.5 135C135.672 135 135 135.672 135 136.5C135 137.328 135.672 138 136.5 138Z"
                fill="#EFF3FD"/>
        </svg>
    )
}

function verticalDots() {
    return (
        <svg width="69" height="165" viewBox="0 0 69 165" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.66129 5.32258C4.13108 5.32258 5.32258 4.13108 5.32258 2.66129C5.32258 1.1915 4.13108 0 2.66129 0C1.1915 0 0 1.1915 0 2.66129C0 4.13108 1.1915 5.32258 2.66129 5.32258Z"
                fill="#EFF3FD"/>
            <path
                d="M34.597 5.32258C36.0667 5.32258 37.2582 4.13108 37.2582 2.66129C37.2582 1.1915 36.0667 0 34.597 0C33.1272 0 31.9357 1.1915 31.9357 2.66129C31.9357 4.13108 33.1272 5.32258 34.597 5.32258Z"
                fill="#EFF3FD"/>
            <path
                d="M66.5323 5.32258C68.0021 5.32258 69.1936 4.13108 69.1936 2.66129C69.1936 1.1915 68.0021 0 66.5323 0C65.0625 0 63.871 1.1915 63.871 2.66129C63.871 4.13108 65.0625 5.32258 66.5323 5.32258Z"
                fill="#EFF3FD"/>
            <path
                d="M2.66129 37.2581C4.13108 37.2581 5.32258 36.0666 5.32258 34.5968C5.32258 33.127 4.13108 31.9355 2.66129 31.9355C1.1915 31.9355 0 33.127 0 34.5968C0 36.0666 1.1915 37.2581 2.66129 37.2581Z"
                fill="#EFF3FD"/>
            <path
                d="M34.597 37.2581C36.0667 37.2581 37.2582 36.0666 37.2582 34.5968C37.2582 33.127 36.0667 31.9355 34.597 31.9355C33.1272 31.9355 31.9357 33.127 31.9357 34.5968C31.9357 36.0666 33.1272 37.2581 34.597 37.2581Z"
                fill="#EFF3FD"/>
            <path
                d="M66.5323 37.2581C68.0021 37.2581 69.1936 36.0666 69.1936 34.5968C69.1936 33.127 68.0021 31.9355 66.5323 31.9355C65.0625 31.9355 63.871 33.127 63.871 34.5968C63.871 36.0666 65.0625 37.2581 66.5323 37.2581Z"
                fill="#EFF3FD"/>
            <path
                d="M2.66129 69.1935C4.13108 69.1935 5.32258 68.002 5.32258 66.5322C5.32258 65.0624 4.13108 63.8709 2.66129 63.8709C1.1915 63.8709 0 65.0624 0 66.5322C0 68.002 1.1915 69.1935 2.66129 69.1935Z"
                fill="#EFF3FD"/>
            <path
                d="M34.597 69.1935C36.0667 69.1935 37.2582 68.002 37.2582 66.5322C37.2582 65.0624 36.0667 63.8709 34.597 63.8709C33.1272 63.8709 31.9357 65.0624 31.9357 66.5322C31.9357 68.002 33.1272 69.1935 34.597 69.1935Z"
                fill="#EFF3FD"/>
            <path
                d="M66.5323 69.1935C68.0021 69.1935 69.1936 68.002 69.1936 66.5322C69.1936 65.0624 68.0021 63.8709 66.5323 63.8709C65.0625 63.8709 63.871 65.0624 63.871 66.5322C63.871 68.002 65.0625 69.1935 66.5323 69.1935Z"
                fill="#EFF3FD"/>
            <path
                d="M2.66129 101.129C4.13108 101.129 5.32258 99.9376 5.32258 98.4678C5.32258 96.998 4.13108 95.8065 2.66129 95.8065C1.1915 95.8065 0 96.998 0 98.4678C0 99.9376 1.1915 101.129 2.66129 101.129Z"
                fill="#EFF3FD"/>
            <path
                d="M34.597 101.129C36.0667 101.129 37.2582 99.9376 37.2582 98.4678C37.2582 96.998 36.0667 95.8065 34.597 95.8065C33.1272 95.8065 31.9357 96.998 31.9357 98.4678C31.9357 99.9376 33.1272 101.129 34.597 101.129Z"
                fill="#EFF3FD"/>
            <path
                d="M66.5323 101.129C68.0021 101.129 69.1936 99.9376 69.1936 98.4678C69.1936 96.998 68.0021 95.8065 66.5323 95.8065C65.0625 95.8065 63.871 96.998 63.871 98.4678C63.871 99.9376 65.0625 101.129 66.5323 101.129Z"
                fill="#EFF3FD"/>
            <path
                d="M2.66129 133.065C4.13108 133.065 5.32258 131.873 5.32258 130.403C5.32258 128.933 4.13108 127.742 2.66129 127.742C1.1915 127.742 0 128.933 0 130.403C0 131.873 1.1915 133.065 2.66129 133.065Z"
                fill="#EFF3FD"/>
            <path
                d="M34.597 133.065C36.0667 133.065 37.2582 131.873 37.2582 130.403C37.2582 128.933 36.0667 127.742 34.597 127.742C33.1272 127.742 31.9357 128.933 31.9357 130.403C31.9357 131.873 33.1272 133.065 34.597 133.065Z"
                fill="#EFF3FD"/>
            <path
                d="M66.5323 133.065C68.0021 133.065 69.1936 131.873 69.1936 130.403C69.1936 128.933 68.0021 127.742 66.5323 127.742C65.0625 127.742 63.871 128.933 63.871 130.403C63.871 131.873 65.0625 133.065 66.5323 133.065Z"
                fill="#EFF3FD"/>
            <path
                d="M2.66129 165C4.13108 165 5.32258 163.809 5.32258 162.339C5.32258 160.869 4.13108 159.677 2.66129 159.677C1.1915 159.677 0 160.869 0 162.339C0 163.809 1.1915 165 2.66129 165Z"
                fill="#EFF3FD"/>
            <path
                d="M34.597 165C36.0667 165 37.2582 163.809 37.2582 162.339C37.2582 160.869 36.0667 159.677 34.597 159.677C33.1272 159.677 31.9357 160.869 31.9357 162.339C31.9357 163.809 33.1272 165 34.597 165Z"
                fill="#EFF3FD"/>
            <path
                d="M66.5323 165C68.0021 165 69.1936 163.809 69.1936 162.339C69.1936 160.869 68.0021 159.677 66.5323 159.677C65.0625 159.677 63.871 160.869 63.871 162.339C63.871 163.809 65.0625 165 66.5323 165Z"
                fill="#EFF3FD"/>
        </svg>
    )
}

function chevronDown() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="d-flex justify-content-center align-items-center p-0 m-0"
             width="24" height="24" viewBox="0 0 24 24">
            <path
                d="M12.7 15.9C12.5683 15.9008 12.4379 15.8755 12.316 15.8258C12.1942 15.776 12.0834 15.7027 11.99 15.61L7.98995 11.61C7.80165 11.4217 7.69586 11.1663 7.69586 10.9C7.69586 10.6337 7.80165 10.3783 7.98995 10.19C8.17826 10.0017 8.43365 9.8959 8.69995 9.8959C8.96626 9.8959 9.22165 10.0017 9.40995 10.19L12.7 13.5L16 10.32C16.0919 10.2177 16.2041 10.1355 16.3293 10.0785C16.4545 10.0215 16.5901 9.99099 16.7277 9.98881C16.8652 9.98663 17.0017 10.0129 17.1287 10.0658C17.2556 10.1188 17.3703 10.1975 17.4655 10.2968C17.5607 10.3961 17.6343 10.514 17.6819 10.6431C17.7294 10.7722 17.7498 10.9097 17.7418 11.047C17.7338 11.1844 17.6975 11.3186 17.6352 11.4413C17.573 11.5639 17.4861 11.6724 17.38 11.76L13.38 15.62C13.1971 15.7963 12.954 15.8964 12.7 15.9Z"/>
        </svg>
    )
}

export default function UserCourses({}) {
    const [setCourseDetails] = useState([]);
    const [levelId, setLevelId] = useState([]);
    const [levelName, setLevelName] = useState([]);
    const [contentTitle, setContentTitle] = useState(null);
    const [educationId, setEducationId] = useState(null);
    const [courses, setCourses] = useState([]);
    const [courseId, setCourseId] = useState(null);
    const [courseLevels, setCourseLevels] = useState([]);
    const [tabs, setTabs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedItem, setSelectedItem] = useState({name: 'Tümü', id: null});
    useEffect(() => {
        api
            .get(`customer-educations`,
                {
                    headers: {
                        "domain": window.location.hostname,
                        "Content-Type": "application/json",
                    }
                }
            )
            .then(res => {
                setTabs(res.data.docs);
            })
            .catch(err => {
                console.log('Error:', err.message, "Courses");
            });
    }, []);
    useEffect(() => {
        if (!["all", null].includes(educationId)) {
            api
                .get(`customer-educations/${educationId}/customer-levels`,
                    {
                        headers: {
                            "domain": window.location.hostname,
                            "Content-Type": "application/json"
                        }
                    }
                )
                .then(res => {
                    setCourseLevels(res.data.docs);
                    console.log(res + " Gelen data LEVELS")
                })
                .catch(err => {
                    console.log('Error:', err.message, "Level");
                });
            api
                .get(`customer-courses?education=${educationId}`,
                    {
                        headers: {
                            "domain": window.location.hostname,
                            "Content-Type": "application/json",

                        }
                    })
                .then(res => {
                    setCourses(res.data.docs);
                    setLoading(false)
                })
                .catch(err => {
                    console.log('Error:', err.message, "Courses");
                });
        } else {
            api
                .get(`customer-courses`,
                    {
                        headers: {
                            "domain": window.location.hostname,
                            "Content-Type": "application/json"
                        }
                    }
                )
                .then(res => {
                    setCourses(res.data.docs);
                    setLoading(false)
                })
                .catch(err => {
                    console.log('Error:', err.message, "Level");
                });
        }
    }, [educationId]);
    
    useEffect(() => {
        {
          if(educationId){
            ["All", null].includes(levelId) ?
              api
                .get(`customer-courses?education=${educationId}`,
                  {
                    headers: {
                      "domain": window.location.hostname,
                      "Content-Type": "application/json"
                    }
                  }
                )
                .then(res => {
                  setCourses(res.data.docs);
                  setLoading(false);
                  console.log(res + " Gelen data levelFilter")
                })
                .catch(err => {
                  console.log('Error:', err.message, "Level");
                })
              :
              api
                .get(`customer-courses?education=${educationId}&level=${levelId}`,
                  {
                    headers: {
                      "domain": window.location.hostname,
                      "Content-Type": "application/json"
                    }
                  }
                )
                .then(res => {
                  setCourses(res.data.docs);
                  setLoading(false);
                  console.log(res + " Gelen data levelFilter")
                })
                .catch(err => {
                  console.log('Error:', err.message, "Level");
                })
          }
          

        }
    }, [levelId]);

    //pagination

    const itemsPerPage = 9;
    const [currentPage, setCurrentPage] = useState(1);
    const [displayedCourses, setDisplayedCourses] = useState([]);

    useEffect(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setDisplayedCourses(courses.slice(startIndex, endIndex));
    }, [currentPage, courses]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const handleTabClick = (item) => {
        setContentTitle(item.title);
        setEducationId(item.id);
        setSelectedItem(item);

        // Eğitim seçimi değiştiğinde düzeyi sıfırla
        setLevelId(null);
        setLevelName(null);
    };

//
    return (
        <div>
            {loading ? <><Loading/></> :
                <div>
                    <div className="account p-0 m-0 border-0 flex-column align-items-center">
                        <div className="decore">
                            <div className="dots-left ">{dots()}</div>
                            <div className="dots-right">{verticalDots()}</div>
                        </div>
                        <div className="accountContent container p-0">
                            <div className="wraper d-flex flex-column">
                                <div className="statsWraper">
                                    <div className="stats">
                                        <div className="stats-top">
                                            <div className="sections">
                                                <h2 className="title">
                                                    {i18n.t("pages.account.courseInf")}
                                                </h2>
                                                <div className="data-area">
                                                    <div className="data-wraper">
                                                        <div
                                                            className="stats-data d-flex flex-column justify-content-center">
                                                            <h3>
                                                                <div>
                                                                    {courses.length}
                                                                </div>
                                                            </h3>
                                                            <h4>{i18n.t("pages.account.total")}</h4>
                                                        </div>
                                                        <div
                                                            className="stats-data d-flex flex-column justify-content-center">
                                                            <h3>
                                                                <div>
                                                                    {/*  tamamlanan ders sayısı*/}
                                                                    {courses.length}
                                                                </div>
                                                            </h3>
                                                            <h4>{i18n.t("pages.account.done")}</h4>
                                                        </div>
                                                        <div
                                                            className="stats-data d-flex flex-column justify-content-center">
                                                            <h3>
                                                                <div>
                                                                    {/* kalan ders sayısı*/}
                                                                    {courses.length}
                                                                </div>
                                                            </h3>
                                                            <h4>{i18n.t("pages.account.waited")}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="langs">
                                            <div className="lang-tabs">
                                                <ul className="nav nav-tabs d-md-flex d-none" id="myTab" role="tablist">
                                                    <li className="nav-item"
                                                        onClick={() => setContentTitle(["Tümü"]) & setEducationId(null)}
                                                        role="presentation">
                                                        <button
                                                            className="nav-link active"
                                                            id="all"
                                                            data-bs-toggle="tab"
                                                            type="button" role="tab"
                                                            aria-controls="home"
                                                            aria-selected="true">{i18n.t("pages.account.all")}
                                                        </button>
                                                    </li>
                                                    {tabs.map(item => {
                                                        return (
                                                            <li className="nav-item"
                                                                onClick={() => {
                                                                    setContentTitle(item.title);
                                                                    setEducationId(item.id);

                                                                    // Eğitim seçildiğinde düzeyi sıfırla
                                                                    setLevelId(null);
                                                                    setLevelName(null);
                                                                }}
                                                                role="presentation"
                                                            >
                                                                <button
                                                                    className="nav-link"
                                                                    id={item.id}
                                                                    data-bs-toggle="tab"
                                                                    data-bs-target="#home"
                                                                    type="button"
                                                                    role="tab"
                                                                    aria-controls="home"
                                                                    aria-selected="true"
                                                                >
                                                                    {item.name}
                                                                </button>
                                                            </li>
                                                        );
                                                    })}

                                                </ul>

                                                <Dropdown
                                                    className="btn-filter d-md-none d-flex border-bottom-0 border-none ">
                                                    <Dropdown.Toggle variant="" id="dropdown-basic"
                                                                     className={"border-none drop-tog"}>
                                                        {selectedItem.name}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            onClick={() => handleTabClick({name: 'Tümü', id: null})}>
                                                            Tümü
                                                        </Dropdown.Item>
                                                        {tabs.map((item) => (
                                                            <Dropdown.Item
                                                                key={item.id}
                                                                onClick={() => handleTabClick(item)}
                                                            >
                                                                {item.name}
                                                            </Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="contentWraper">
                                    <div className="postContent d-flex">
                                        <div className="tabWraper w-100">
                                            <div className="tab-pane active" id="home" role="tabpanel"
                                                 aria-labelledby="home-tab">

                                                <div className="tab-content ">
                                                    <h1 className="tab-heading p-0 m-0">
                                                        {["all", null].includes(educationId) ?
                                                            i18n.t("pages.account.all") :
                                                            contentTitle
                                                        }
                                                    </h1>
                                                    {
                                                        ["all", null].includes(educationId) ?
                                                            <Dropdown className="tab-description">
                                                                <Dropdown.Toggle hidden={true}>
                                                                    <p className="d-flex align-items-center justify-content-center p-0 m-0"> {i18n.t("pages.account.all")} {chevronDown()}</p>
                                                                </Dropdown.Toggle>
                                                            </Dropdown>
                                                            :
                                                            <Dropdown className="tab-description">
                                                                <Dropdown.Toggle>
                                                                    <p className="d-flex align-items-center justify-content-center p-0 m-0">{levelName === null ? i18n.t("pages.account.level") : `${levelName} ${i18n.t("pages.account.level")}`} {chevronDown()}</p>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item
                                                                        onClick={() => setLevelId(null) & setLevelName(null)}>{i18n.t("pages.account.all")}</Dropdown.Item>
                                                                    <Dropdown.Divider/>
                                                                    {courseLevels.map(item => {
                                                                        return (
                                                                            <>
                                                                                <Dropdown.Item href="#/action-1"
                                                                                               onClick={() => setLevelId(item.id) & setLevelName(item.name)}>{item.name} {i18n.t("pages.account.level")}</Dropdown.Item>
                                                                            </>

                                                                        )
                                                                    })}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                    }
                                                </div>
                                                {courses.length <= 0 ?
                                                    <div
                                                        className="d-flex justify-content-center align-items-center postsNull">
                                                        <div className="noneOfPosts">
                                                            {i18n.t("pages.account.emptyCourse")}
                                                        </div>
                                                    </div>
                                                    :
                                                    <div
                                                        className="row row-cols-xs-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 posts justify-content-start">
                                                        {displayedCourses.map((course, key) => (
                                                            <>
                                                                <Link to={course.id}
                                                                      onClick={() => setCourseId(course.id)}
                                                                      className="col" key={course.id}>
                                                                    <div className="card cards">
                                                                        <Card.Img variant="top"
                                                                                  src={course.image}/>
                                                                        <div className="playCase">
                                                                            <div className="play p-0 m-0">
                                                                                <svg className="playButton"
                                                                                     xmlns="http://www.w3.org/2000/svg"
                                                                                     width="12"
                                                                                     height="14" viewBox="0 0 12 14"
                                                                                     fill="none">
                                                                                    <path
                                                                                        d="M2.61162e-05 0.875986C-0.00280274 0.723869 0.0349154 0.57374 0.109306 0.441023C0.183696 0.308306 0.292077 0.197785 0.423314 0.120815C0.554552 0.0438437 0.703914 0.00319772 0.856058 0.00305215C1.0082 0.00290658 1.15764 0.0432666 1.28903 0.119986L11.575 6.24399C11.7048 6.32282 11.812 6.43374 11.8865 6.56607C11.9609 6.69839 12 6.84766 12 6.99949C12 7.15131 11.9609 7.30058 11.8865 7.43291C11.812 7.56523 11.7048 7.67615 11.575 7.75499L1.28903 13.88C1.15764 13.9567 1.0082 13.9971 0.856058 13.9969C0.703914 13.9968 0.554552 13.9561 0.423314 13.8792C0.292077 13.8022 0.183696 13.6917 0.109306 13.5589C0.0349154 13.4262 -0.00280274 13.2761 2.61162e-05 13.124V0.875986Z"
                                                                                        fill="#849BFF"/>
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                        <Card.Body className="content">
                                                                            <Card.Title
                                                                                className="course-title">{course.name}</Card.Title>
                                                                            <Card.Text
                                                                                className="course-desc"><p
                                                                                dangerouslySetInnerHTML={{__html: course.shortDescription}}></p>
                                                                            </Card.Text>
                                                                            <Card.Text>
                                                                                <div
                                                                                    className={"d-flex card-desc-level"}>
                                                                                    {course.level.map((item, key) => (
                                                                                        <div className={"pb-4 mb-4"}
                                                                                             key={item.id}>
                                                                                            {key < course.level.length - 1 ? (
                                                                                                <div
                                                                                                    className={"pe-2"}>{item.name}-</div>
                                                                                            ) : (
                                                                                                <div
                                                                                                    className={"pe-2"}>{item.name}</div>
                                                                                            )}
                                                                                        </div>
                                                                                    ))}

                                                                                </div>
                                                                            </Card.Text>
                                                                        </Card.Body>
                                                                    </div>
                                                                </Link>
                                                            </>
                                                        ))}
                                                        <Pagination>
                                                            <Pagination.Prev
                                                                onClick={() => handlePageChange(currentPage - 1)}
                                                                disabled={currentPage === 1}
                                                            />
                                                            {Array.from({length: Math.ceil(courses.length / itemsPerPage)}, (_, index) => (
                                                                <Pagination.Item
                                                                    key={index}
                                                                    active={index + 1 === currentPage}
                                                                    onClick={() => handlePageChange(index + 1)}
                                                                >
                                                                    {index + 1}
                                                                </Pagination.Item>
                                                            ))}
                                                            <Pagination.Next
                                                                onClick={() => handlePageChange(currentPage + 1)}
                                                                disabled={currentPage === Math.ceil(courses.length / itemsPerPage)}
                                                            />
                                                        </Pagination>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

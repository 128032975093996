import React, {useEffect, useState} from "react";
import {Link, useNavigate} from 'react-router-dom';
import logo from "../style/images/logo/logo.svg";
import decoration from "../style/images/img/decoration.png";
import {useTranslation} from "react-i18next";
import Slider from "../components/Slider";
import Button from "react-bootstrap/Button";
import logoMini from "../style/images/logo/logo.svg";
import ResetPasswordSuccessStatus from "../components/Status/ResetPasswordSuccessStatus";
import {useUserContext} from "../context/UserContext";
import {FaEye, FaEyeSlash} from "react-icons/fa";

function decorativeDots() {
    return (
        <div className="d-flex z-0">
            <img src={decoration} alt="" className="z-0 d-flex" style={{width: "110px", height: "110px"}}/>
            <img src={decoration} alt="" className="z-0 d-flex" style={{width: "110px", height: "110px"}}/>
        </div>
    )
}

export default function ResetPasswordPage() {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, []);

    const {resetPassword, setStatus} = useUserContext();
    const [isSuccess, setIsSuccess] = useState(false);
    //
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
//
    const [isPasswordEmpty, setIsPasswordEmpty] = useState(false);
    const [isPasswordRepeatEmpty, setIsPasswordRepeatEmpty] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isPasswordLengthError, setIsPasswordLengthError] = useState(false);
    const [isPasswordRepeatValid, setIsPasswordRepeatValid] = useState(false);
    const [isPasswordMatchError, setIsPasswordMatchError] = useState(false);
//
    const [showPassword, setShowPassword] = useState(false);
//
    const [isResetPasswordSuccessful, setIsResetPasswordSuccessful] = useState(false);
    //
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    //
    const handlePasswordToggle = () => {
        setShowPassword(!showPassword);
    };
    const handlePasswordRepeatToggle = () => {
        setShowPasswordRepeat(!showPasswordRepeat);
    };

    const clearPasswordError = () => {
        setIsPasswordEmpty(false);
        setIsPasswordValid(false);
    };
    const clearPasswordRepeatError = () => {
        setIsPasswordRepeatEmpty(false);
        setIsPasswordRepeatValid(false);
    };

    const passwordsMatch = () => {
        return password === passwordRepeat;
    };

    const resetForm = () => {
        setPassword('');
        setPasswordRepeat('');
    };
    useEffect(() => {
        resetForm();
    }, []);

    const handleResetPassword = async (e) => {
        e.preventDefault();
        let hasError = false;
        //
        if (password === '') {
            setIsPasswordEmpty(true);
            hasError = true;
        } else {
            setIsPasswordEmpty(false);
        }

        if (passwordRepeat === '') {
            setIsPasswordRepeatEmpty(true);
            hasError = true;
        } else {
            setIsPasswordRepeatEmpty(false);
        }
        if (!passwordsMatch()) {
            setIsPasswordMatchError(true);
            hasError = true;
        } else {
            setIsPasswordMatchError(false);
        }
        //
        if (password.length < 1) {
            setIsPasswordLengthError(true);
            hasError = true;
        } else {
            setIsPasswordLengthError(false);
        }
        if (hasError) {
            setIsFormSubmitted(true);
            setIsErrorVisible(true);
            return;
        }
        try {
            if (!passwordsMatch()) {
                setIsPasswordMatchError(true);
                return;
            }
            await resetPassword(password, passwordRepeat);
            setIsResetPasswordSuccessful(true);
        } catch (error) {
            console.error('Reset Password error:', error);
        }
    };

    useEffect(() => {
        if (isSuccess) {
            const redirectTimer = setTimeout(() => {
                navigate('/');
            }, 2500);
            return () => clearTimeout(redirectTimer);
        }
    }, [isSuccess, navigate]);
    return (
        <div className="">
            <div className="form-page-header d-sm-flex d-none z-3">
                <div className="logo d-flex z-3">
                    <nav className="navbar pt-4 navbar-expand-lg pb-0 d-flex justify-content-start z-3">
                        <Link className="navbar-brand p-0 m-0 z-3" to="/">
                            <img src={logoMini} alt="Yabancıdil.net" className="p-0 m-0"/>
                        </Link>
                    </nav>
                </div>
            </div>
            <div className="form-page-header d-flex d-sm-none position-relative z-3">
                <div className="logo d-block position-relative  z-3">
                    <nav className="navbar pt-4 navbar-expand-lg d-flex justify-content-start ">
                        <Link className="navbar-brand" to="/">
                            <img src={logo} alt={i18n.t("pages.login.logo.alt")} className="p-0 m-0"/>
                        </Link>
                    </nav>
                </div>
            </div>
            <hr className="d-sm-none"/>
            <section className="form-page login-form-page ">
                <div className="login-form-page-content d-flex ">
                    <div className="form-page-left d-none d-md-flex h-100 form-page-half text-center">
                        <Slider/>
                        <div className="position-absolute start-0 top-0 d-flex justify-content-end"
                             style={{width: "50%", height: "100%"}}>{decorativeDots()}</div>
                        <div className="position-absolute start-0 top-0 d-flex justify-content-start align-items-end"
                             style={{width: "50%", height: "100%"}}>{decorativeDots()}</div>
                    </div>
                    <div className="form-page-right d-flex justify-content-center align-items-center">
                        <div className="form-page-right-content ">
                            {isSuccess ? <ResetPasswordSuccessStatus title={i18n.t("pages.forget.status.success.title")}
                                                                     description={i18n.t("pages.forget.status.success.description")}/>
                                :
                                <>
                                    <h2 className="sec-heading m-0 ">{i18n.t("pages.reset.title")}</h2>
                                    <p className="sec-brief mt-4 mb-10">{i18n.t("pages.reset.description")}</p>
                                    <form className="login-form" onSubmit={handleResetPassword}>
                                        <div className="form-group mb-4  position-relative">
                                            <label className="form-label mb-4"
                                                   htmlFor="password">{i18n.t("pages.reset.pass1")}</label>
                                            <input
                                                className={` ${isPasswordEmpty || isPasswordMatchError ? 'form-control form-control-err' : 'form-control'}`}
                                                id="password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={password}
                                                onFocus={() => {
                                                    clearPasswordError();
                                                    setStatus(true);
                                                }}
                                                onChange={(e) => setPassword(e.target.value)}
                                                placeholder={i18n.t("pages.reset.passPlaceHolder")}/>
                                            <button onClick={handlePasswordToggle} tabIndex={3}
                                                    id="showPassword"
                                                    type="button"
                                                    className="bg-transparent position-absolute border-0 d-flex justify-content-center align-items-center p-0 m-0 w-auto h-auto"
                                                    style={{
                                                        right: "16px",
                                                        top: "65px"
                                                    }}>
                                                {showPassword ?  <FaEye color={'#cac6d4'} size={24} />: <FaEyeSlash color={'#cac6d4'} size={24} />}
                                            </button>
                                            {isPasswordEmpty &&
                                                <p className={"error-text"}>{i18n.t("pages.reset.passwordEmpty")}</p>
                                            }
                                        </div>
                                        <div className="form-group position-relative">
                                            <label className="form-label mb-4"
                                                   htmlFor="passwordRepeat">{i18n.t("pages.reset.pass2")}</label>
                                            <input
                                                className={` ${isPasswordRepeatEmpty || isPasswordMatchError ? 'form-control form-control-err' : 'form-control'}`}
                                                id="passwordRepeat"
                                                type={showPasswordRepeat ? 'text' : 'password'}
                                                value={passwordRepeat}
                                                onFocus={() => {
                                                    clearPasswordRepeatError();
                                                    setStatus(true);
                                                }}
                                                onChange={(e) => setPasswordRepeat(e.target.value)}
                                                placeholder={i18n.t("pages.reset.passPlaceHolder")}/>

                                            <button onClick={handlePasswordRepeatToggle} tabIndex={3}
                                                    id="showPassword"
                                                    type="button"
                                                    className="bg-transparent position-absolute border-0 d-flex justify-content-center align-items-center p-0 m-0 w-auto h-auto"
                                                    style={{
                                                        right: "16px",
                                                        top: "65px"
                                                    }}>
                                                {showPasswordRepeat ?  <FaEye color={'#cac6d4'} size={24} />: <FaEyeSlash color={'#cac6d4'} size={24} />}
                                            </button>
                                            {isPasswordRepeatEmpty &&
                                                <p className={"error-text"}>{i18n.t("pages.reset.passwordRepeatEmpty")}</p>
                                            }
                                        </div>
                                        <Button className="btn btn-primary w-100 submit-btn mt-4"
                                                type={"submit"}>{i18n.t("pages.reset.submitButton")}</Button>
                                    </form>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

import React, { useState} from "react";
import {Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import '../style/components/Footer.scss';
import {Link} from "react-router-dom";
import axios from "axios";
import i18n from "../i18n";
export default function Footer() {
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [isFormEmpty, setIsFormEmpty] = useState(null);
    const [isEmailEmpty, setIsEmailEmpty] = useState(null);
    const [setIsPasswordEmpty, isPasswordEmpty] = useState(null);
    const [isMessageEmpty, setIsMessageEmpty] = useState(null);
    const [isEmailLengthError, setIsEmailLengthError] = useState(null);
    const [isSubjectLengthError, setIsSubjectLengthError] = useState(null);
    const [saveAction, setSaveAction] = useState(null);
    const [isEmailFormatInvalid, setIsEmailFormatInvalid] = useState(null);
    const [isSubjectEmpty, setIsSubjectEmpty] = useState(null);

    const token = localStorage.getItem('token')
    const handleForm = async (e) => {
        e.preventDefault();
        if (email === '' && subject === '' && message === '') {
            setIsFormEmpty(true);
            return;
        } else {
            setIsFormEmpty(false);
        }

        if (email === '') {
            setIsEmailEmpty(true);
            return;
        } else {
            setIsEmailEmpty(false);
        }

        if (subject === '') {
            setIsSubjectEmpty(true);
            return;
        } else {
            setIsSubjectEmpty(false);
        }

        if (message === '') {
            setIsMessageEmpty(true);
            return;
        } else {
            setIsMessageEmpty(false);
        }

        if (email.length <= 5) {
            setIsEmailLengthError(true);
            return;
        } else {
            setIsEmailLengthError(false);

        }
        if (subject.length < 3) {
            setIsSubjectLengthError(true);
            return;
        } else {
            setIsSubjectLengthError(false);
        }

        if (message.length < 3) {
            setIsSubjectLengthError(true);
            return;
        } else {
            setIsSubjectLengthError(false);
        }
        // E-posta formatı doğrulaması
        const emailPattern = /^[a-zA-Z0-9._-]+@([a-zA-Z0-9.-]+.)+([.])+[a-zA-Z0-9.-]{2,4}$/;
        if (!emailPattern.test(email)) {
            setIsEmailFormatInvalid(true);
            return;
        } else {
            setIsEmailFormatInvalid(false);
        }
        try {
            await form(email, subject, message);
            console.log("Başarılı")
        } catch (error) {
            console.error('Başarısız:', error);

        }

        const form = await axios.post('https://64e5b7c309e64530d17ee6ab.mockapi.io/contactform', {
            email: `${email}`, subject: `${subject}`, message: `${message}`,
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    return (

        <React.Fragment>
            {token ? <div className="footerBg">
                <form className="footer container z-3 p-0 position-relative d-flex flex-column" onSubmit={handleForm}>
                    <h2 className="sec-heading">{i18n.t("pages.account.kit")}</h2>
                    <p className="sec-brief">{i18n.t("pages.account.kitDesc")}</p>

                    {isFormEmpty ?
                        <div className="firstRow d-flex">
                            <Form.Group className="z-3 emailform d-flex flex-column" controlId="formBasicEmail">
                                <Form.Control type="email" className="email z-3" name="email"
                                              placeholder={i18n.t("pages.account.email")}/>
                            </Form.Group>
                            <Form.Group className="z-3 subjform d-flex flex-column" controlId="formBasicSubject">
                                <Form.Control type="text" className="subject z-3" name="subject" placeholder="Konu"/>
                            </Form.Group>

                        </div>
                        :
                        <div className="firstRow d-flex">
                            {isEmailEmpty ?
                                <Form.Group className="z-3 emailform d-flex flex-column" controlId="formBasicEmail">
                                    <Form.Control type="email" className="email z-3" name="email"
                                                  placeholder={i18n.t("pages.account.email")}/>
                                </Form.Group>
                                :
                                <Form.Group className="z-3 emailform" controlId="formBasicEmail">
                                    <Form.Control type="email" className="email z-3" name="email"
                                                  placeholder={i18n.t("pages.account.email")}/>
                                </Form.Group>
                            }
                            {isSubjectEmpty ?
                                <Form.Group className="z-3 subjform d-flex flex-column" controlId="formBasicSubject">
                                    <Form.Control type="text" className="subject z-3" name="subject" placeholder={i18n.t("pages.account.subject")}/>
                                </Form.Group>
                                :
                                <Form.Group className="z-3 subjform" controlId="formBasicSubject">
                                    <Form.Control type="text" className="subject z-3" name="subject" placeholder={i18n.t("pages.account.subject")}/>
                                </Form.Group>
                            }
                        </div>
                    }

                    {isSubjectEmpty ?
                        <Form.Group className="textform z-3 d-flex flex-column">
                            <Form.Control as="textarea" className="textarea z-3" name="message"
                                          placeholder={i18n.t("pages.account.message")}></Form.Control>
                            skjnsdckjcnsdkjcns
                        </Form.Group>
                        :
                        <Form.Group className="textform z-3">
                            <Form.Control as="textarea" className="textarea z-3" name="message"
                                          placeholder={i18n.t("pages.account.message")}></Form.Control>
                        </Form.Group>
                    }

                    <div className="buttonSend d-flex justify-content-end z-3">
                        <Button variant="primary" className="buttonns z-3" type="submit">
                            {i18n.t("pages.account.submitButton")}
                        </Button>
                    </div>
                </form>


                <div className="copyright d-none d-xl-block d-lg-block d-md-none pt-5" >
                    <div className="copyrightInline container" style={{marginTop:"-10px"}}>
                        <div className="copyText">{i18n.t("pages.account.copyright")}</div>
                        <hr/>
                        <div className="d-flex icons">
                            <Link to="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29"
                                     fill="none">
                                    <path
                                        d="M19.9062 4.01972C19.9064 3.94082 19.8912 3.86265 19.8616 3.7897C19.8321 3.71675 19.7886 3.65043 19.7339 3.59454C19.6791 3.53865 19.614 3.49427 19.5423 3.46395C19.4707 3.43363 19.3939 3.41796 19.3162 3.41783H16.3612C14.8733 3.34271 13.4165 3.86945 12.3093 4.88296C11.2021 5.89647 10.5344 7.31435 10.4522 8.82678V12.0711H7.49725C7.34085 12.0711 7.19083 12.1342 7.08006 12.2464C6.96928 12.3587 6.90678 12.511 6.90625 12.67V15.7944C6.90625 15.9537 6.96852 16.1066 7.07935 16.2193C7.19018 16.3319 7.34051 16.3953 7.49725 16.3953H10.4522V24.4467C10.4522 24.606 10.5145 24.7589 10.6253 24.8716C10.7362 24.9842 10.8865 25.0475 11.0432 25.0475H14.5892C14.746 25.0475 14.8963 24.9842 15.0071 24.8716C15.118 24.7589 15.1802 24.606 15.1802 24.4467V16.3963H18.2802C18.4116 16.3982 18.5398 16.3555 18.6447 16.2751C18.7495 16.1946 18.825 16.0809 18.8592 15.952L19.7102 12.8276C19.7338 12.7388 19.737 12.6457 19.7196 12.5555C19.7023 12.4652 19.6647 12.3803 19.61 12.3071C19.5552 12.2339 19.4846 12.1745 19.4037 12.1334C19.3228 12.0923 19.2337 12.0707 19.1432 12.0701H15.1792V8.82678C15.2086 8.52928 15.346 8.25359 15.5645 8.05355C15.7831 7.85351 16.0672 7.7435 16.3612 7.74499H19.3162C19.4728 7.74472 19.6229 7.68129 19.7335 7.56864C19.8441 7.45598 19.9062 7.3033 19.9062 7.14411V4.01972Z"
                                        fill="white"/>
                                </svg>
                            </Link>
                            <Link to="/">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29"
                                     fill="none">
                                    <path
                                        d="M9.42969 23.7228C11.1548 23.7815 12.8741 23.4881 14.4858 22.8598C16.0975 22.2315 17.5689 21.2811 18.8132 20.0647C20.0575 18.8484 21.0493 17.3909 21.7301 15.7782C22.4108 14.1655 22.7667 12.4305 22.7767 10.6756C23.5675 9.68072 24.1547 8.53516 24.5037 7.30625C24.5299 7.2092 24.5279 7.10653 24.4979 7.01061C24.468 6.9147 24.4114 6.82964 24.335 6.7657C24.2585 6.70175 24.1655 6.66165 24.0672 6.65023C23.9689 6.63881 23.8694 6.65655 23.7807 6.70132C23.3672 6.90383 22.9015 6.9693 22.4494 6.8885C21.9973 6.8077 21.5816 6.5847 21.2607 6.25093C20.8513 5.79479 20.356 5.42686 19.8041 5.16893C19.2523 4.911 18.6551 4.76833 18.048 4.74937C17.4409 4.73041 16.8363 4.83554 16.2699 5.05854C15.7035 5.28154 15.1869 5.61787 14.7507 6.04759C14.1523 6.63513 13.7137 7.37019 13.4777 8.18121C13.2416 8.99223 13.2161 9.85157 13.4037 10.6756C9.50369 10.9125 6.81668 9.0265 4.66967 6.44105C4.60553 6.36604 4.52116 6.3118 4.42702 6.28504C4.33287 6.25827 4.23307 6.26014 4.13996 6.29044C4.04686 6.32073 3.96453 6.3781 3.90316 6.45547C3.84178 6.53283 3.80407 6.62679 3.79467 6.72572C3.3857 9.03212 3.68073 11.4105 4.64014 13.5415C5.59954 15.6724 7.17737 17.4538 9.16168 18.6464C7.83124 20.1977 5.96291 21.1666 3.94667 21.3508C3.83867 21.3691 3.73899 21.4212 3.66158 21.4999C3.58417 21.5787 3.53289 21.68 3.51493 21.7898C3.49696 21.8996 3.51321 22.0124 3.5614 22.1123C3.60959 22.2122 3.6873 22.2944 3.78367 22.3472C5.5379 23.2384 7.46969 23.7089 9.43069 23.7228"
                                        fill="white"/>
                                </svg>
                            </Link>
                            <Link to="/">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29"
                                     fill="none">
                                    <path
                                        d="M20.4175 16.6043C20.076 17.8123 19.3991 18.8945 18.4679 19.7213C17.5367 20.5481 16.3908 21.0843 15.1675 21.2657C14.2108 21.4069 13.236 21.35 12.3015 21.0986C11.367 20.8472 10.4921 20.4065 9.72937 19.8027C8.9666 19.199 8.33169 18.4448 7.86275 17.5855C7.39381 16.7261 7.10051 15.7792 7.00046 14.8017C6.92299 13.8204 7.04663 12.8334 7.36355 11.9031C7.68048 10.9729 8.1838 10.1196 8.8417 9.39719C9.4996 8.67481 10.2978 8.09907 11.1857 7.70635C12.0737 7.31364 13.0321 7.11251 14.0005 7.11566C14.9077 7.11689 15.8064 7.2939 16.6485 7.63721C16.7811 7.69792 16.9313 7.70602 17.0695 7.65994C17.2077 7.61385 17.324 7.5169 17.3955 7.38813L19.0755 4.24457C19.1117 4.17217 19.1334 4.09322 19.1395 4.01225C19.1456 3.93127 19.136 3.84987 19.1111 3.7727C19.0862 3.69554 19.0465 3.62413 18.9944 3.56256C18.9423 3.501 18.8788 3.4505 18.8075 3.41395C17.0032 2.5834 15.0207 2.23348 13.0467 2.39717C11.0727 2.56086 9.17214 3.23277 7.52412 4.34959C5.87611 5.46642 4.53487 6.99139 3.62677 8.78086C2.71866 10.5703 2.27358 12.5654 2.33346 14.578C2.4486 17.567 3.65727 20.4046 5.7227 22.5349C7.78812 24.6653 10.5615 25.9348 13.4995 26.0949C16.577 26.2335 19.5835 25.129 21.8631 23.0225C24.1426 20.9159 25.5102 17.9783 25.6675 14.8505V12.4786C25.6632 12.3228 25.6004 12.1745 25.492 12.0643C25.3836 11.9541 25.2377 11.8903 25.0845 11.8859H14.5845C14.5079 11.8859 14.4321 11.9012 14.3614 11.931C14.2906 11.9608 14.2264 12.0045 14.1722 12.0595C14.1181 12.1145 14.0751 12.1799 14.0458 12.2518C14.0166 12.3237 14.0015 12.4008 14.0015 12.4786V16.037C14.0015 16.1148 14.0166 16.1919 14.0458 16.2638C14.0751 16.3357 14.1181 16.4011 14.1722 16.4561C14.2264 16.5111 14.2906 16.5548 14.3614 16.5846C14.4321 16.6144 14.5079 16.6297 14.5845 16.6297H20.4175"
                                        fill="white"/>
                                </svg>
                            </Link>
                            <Link to="/">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29"
                                     fill="none">
                                    <path
                                        d="M17.6766 9.9644C16.7823 9.96106 15.8962 10.1371 15.069 10.4824C14.2417 10.8277 13.4896 11.3354 12.8556 11.9766C12.2217 12.6179 11.7183 13.3799 11.3744 14.2191C11.0306 15.0584 10.8529 15.9583 10.8516 16.8675V23.8417C10.8516 24.1248 10.9622 24.3964 11.1591 24.5965C11.356 24.7967 11.6231 24.9092 11.9016 24.9092H14.3516C14.63 24.9092 14.8971 24.7967 15.094 24.5965C15.2909 24.3964 15.4016 24.1248 15.4016 23.8417V16.8675C15.4012 16.5441 15.468 16.2244 15.5974 15.929C15.7268 15.6336 15.916 15.3693 16.1526 15.1533C16.3893 14.9373 16.6681 14.7744 16.9708 14.6752C17.2735 14.5761 17.5934 14.543 17.9096 14.578C18.4768 14.6504 18.9981 14.9321 19.3745 15.3695C19.7509 15.807 19.9563 16.3697 19.9516 16.9508V23.8417C19.9516 24.1248 20.0622 24.3964 20.2591 24.5965C20.456 24.7967 20.7231 24.9092 21.0016 24.9092H23.4516C23.7301 24.9092 23.9971 24.7967 24.194 24.5965C24.391 24.3964 24.5016 24.1248 24.5016 23.8417V16.8675C24.5003 15.9583 24.3226 15.0584 23.9787 14.2191C23.6348 13.3799 23.1315 12.6179 22.4975 11.9766C21.8636 11.3354 21.1114 10.8277 20.2842 10.4824C19.4569 10.1371 18.5708 9.96106 17.6766 9.9644Z"
                                        fill="white"/>
                                    <path
                                        d="M7.85 11.0316H4.4C3.90294 11.0316 3.5 11.4413 3.5 11.9466V23.9943C3.5 24.4996 3.90294 24.9093 4.4 24.9093H7.85C8.34706 24.9093 8.75 24.4996 8.75 23.9943V11.9466C8.75 11.4413 8.34706 11.0316 7.85 11.0316Z"
                                        fill="white"/>
                                    <path
                                        d="M6.125 8.8966C7.57475 8.8966 8.75 7.70176 8.75 6.22784C8.75 4.75393 7.57475 3.55908 6.125 3.55908C4.67525 3.55908 3.5 4.75393 3.5 6.22784C3.5 7.70176 4.67525 8.8966 6.125 8.8966Z"
                                        fill="white"/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="copyright d-xl-none d-lg-none pb-5">
                    <div className="copyrightInline container  mt-3 ">
                        <div className="copyText">{i18n.t("pages.account.copyright")}</div>
                        <hr/>
                        <div className="col-12 d-flex justify-content-center mt-3 ">

                            <div className="d-flex icons">
                                <Link to="">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29"
                                         fill="none">
                                        <path
                                            d="M19.9062 4.01972C19.9064 3.94082 19.8912 3.86265 19.8616 3.7897C19.8321 3.71675 19.7886 3.65043 19.7339 3.59454C19.6791 3.53865 19.614 3.49427 19.5423 3.46395C19.4707 3.43363 19.3939 3.41796 19.3162 3.41783H16.3612C14.8733 3.34271 13.4165 3.86945 12.3093 4.88296C11.2021 5.89647 10.5344 7.31435 10.4522 8.82678V12.0711H7.49725C7.34085 12.0711 7.19083 12.1342 7.08006 12.2464C6.96928 12.3587 6.90678 12.511 6.90625 12.67V15.7944C6.90625 15.9537 6.96852 16.1066 7.07935 16.2193C7.19018 16.3319 7.34051 16.3953 7.49725 16.3953H10.4522V24.4467C10.4522 24.606 10.5145 24.7589 10.6253 24.8716C10.7362 24.9842 10.8865 25.0475 11.0432 25.0475H14.5892C14.746 25.0475 14.8963 24.9842 15.0071 24.8716C15.118 24.7589 15.1802 24.606 15.1802 24.4467V16.3963H18.2802C18.4116 16.3982 18.5398 16.3555 18.6447 16.2751C18.7495 16.1946 18.825 16.0809 18.8592 15.952L19.7102 12.8276C19.7338 12.7388 19.737 12.6457 19.7196 12.5555C19.7023 12.4652 19.6647 12.3803 19.61 12.3071C19.5552 12.2339 19.4846 12.1745 19.4037 12.1334C19.3228 12.0923 19.2337 12.0707 19.1432 12.0701H15.1792V8.82678C15.2086 8.52928 15.346 8.25359 15.5645 8.05355C15.7831 7.85351 16.0672 7.7435 16.3612 7.74499H19.3162C19.4728 7.74472 19.6229 7.68129 19.7335 7.56864C19.8441 7.45598 19.9062 7.3033 19.9062 7.14411V4.01972Z"
                                            fill="white"/>
                                    </svg>
                                </Link>
                                <Link to="/">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29"
                                         fill="none">
                                        <path
                                            d="M9.42969 23.7228C11.1548 23.7815 12.8741 23.4881 14.4858 22.8598C16.0975 22.2315 17.5689 21.2811 18.8132 20.0647C20.0575 18.8484 21.0493 17.3909 21.7301 15.7782C22.4108 14.1655 22.7667 12.4305 22.7767 10.6756C23.5675 9.68072 24.1547 8.53516 24.5037 7.30625C24.5299 7.2092 24.5279 7.10653 24.4979 7.01061C24.468 6.9147 24.4114 6.82964 24.335 6.7657C24.2585 6.70175 24.1655 6.66165 24.0672 6.65023C23.9689 6.63881 23.8694 6.65655 23.7807 6.70132C23.3672 6.90383 22.9015 6.9693 22.4494 6.8885C21.9973 6.8077 21.5816 6.5847 21.2607 6.25093C20.8513 5.79479 20.356 5.42686 19.8041 5.16893C19.2523 4.911 18.6551 4.76833 18.048 4.74937C17.4409 4.73041 16.8363 4.83554 16.2699 5.05854C15.7035 5.28154 15.1869 5.61787 14.7507 6.04759C14.1523 6.63513 13.7137 7.37019 13.4777 8.18121C13.2416 8.99223 13.2161 9.85157 13.4037 10.6756C9.50369 10.9125 6.81668 9.0265 4.66967 6.44105C4.60553 6.36604 4.52116 6.3118 4.42702 6.28504C4.33287 6.25827 4.23307 6.26014 4.13996 6.29044C4.04686 6.32073 3.96453 6.3781 3.90316 6.45547C3.84178 6.53283 3.80407 6.62679 3.79467 6.72572C3.3857 9.03212 3.68073 11.4105 4.64014 13.5415C5.59954 15.6724 7.17737 17.4538 9.16168 18.6464C7.83124 20.1977 5.96291 21.1666 3.94667 21.3508C3.83867 21.3691 3.73899 21.4212 3.66158 21.4999C3.58417 21.5787 3.53289 21.68 3.51493 21.7898C3.49696 21.8996 3.51321 22.0124 3.5614 22.1123C3.60959 22.2122 3.6873 22.2944 3.78367 22.3472C5.5379 23.2384 7.46969 23.7089 9.43069 23.7228"
                                            fill="white"/>
                                    </svg>
                                </Link>
                                <Link to="/">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29"
                                         fill="none">
                                        <path
                                            d="M20.4175 16.6043C20.076 17.8123 19.3991 18.8945 18.4679 19.7213C17.5367 20.5481 16.3908 21.0843 15.1675 21.2657C14.2108 21.4069 13.236 21.35 12.3015 21.0986C11.367 20.8472 10.4921 20.4065 9.72937 19.8027C8.9666 19.199 8.33169 18.4448 7.86275 17.5855C7.39381 16.7261 7.10051 15.7792 7.00046 14.8017C6.92299 13.8204 7.04663 12.8334 7.36355 11.9031C7.68048 10.9729 8.1838 10.1196 8.8417 9.39719C9.4996 8.67481 10.2978 8.09907 11.1857 7.70635C12.0737 7.31364 13.0321 7.11251 14.0005 7.11566C14.9077 7.11689 15.8064 7.2939 16.6485 7.63721C16.7811 7.69792 16.9313 7.70602 17.0695 7.65994C17.2077 7.61385 17.324 7.5169 17.3955 7.38813L19.0755 4.24457C19.1117 4.17217 19.1334 4.09322 19.1395 4.01225C19.1456 3.93127 19.136 3.84987 19.1111 3.7727C19.0862 3.69554 19.0465 3.62413 18.9944 3.56256C18.9423 3.501 18.8788 3.4505 18.8075 3.41395C17.0032 2.5834 15.0207 2.23348 13.0467 2.39717C11.0727 2.56086 9.17214 3.23277 7.52412 4.34959C5.87611 5.46642 4.53487 6.99139 3.62677 8.78086C2.71866 10.5703 2.27358 12.5654 2.33346 14.578C2.4486 17.567 3.65727 20.4046 5.7227 22.5349C7.78812 24.6653 10.5615 25.9348 13.4995 26.0949C16.577 26.2335 19.5835 25.129 21.8631 23.0225C24.1426 20.9159 25.5102 17.9783 25.6675 14.8505V12.4786C25.6632 12.3228 25.6004 12.1745 25.492 12.0643C25.3836 11.9541 25.2377 11.8903 25.0845 11.8859H14.5845C14.5079 11.8859 14.4321 11.9012 14.3614 11.931C14.2906 11.9608 14.2264 12.0045 14.1722 12.0595C14.1181 12.1145 14.0751 12.1799 14.0458 12.2518C14.0166 12.3237 14.0015 12.4008 14.0015 12.4786V16.037C14.0015 16.1148 14.0166 16.1919 14.0458 16.2638C14.0751 16.3357 14.1181 16.4011 14.1722 16.4561C14.2264 16.5111 14.2906 16.5548 14.3614 16.5846C14.4321 16.6144 14.5079 16.6297 14.5845 16.6297H20.4175"
                                            fill="white"/>
                                    </svg>
                                </Link>
                                <Link to="/">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29"
                                         fill="none">
                                        <path
                                            d="M17.6766 9.9644C16.7823 9.96106 15.8962 10.1371 15.069 10.4824C14.2417 10.8277 13.4896 11.3354 12.8556 11.9766C12.2217 12.6179 11.7183 13.3799 11.3744 14.2191C11.0306 15.0584 10.8529 15.9583 10.8516 16.8675V23.8417C10.8516 24.1248 10.9622 24.3964 11.1591 24.5965C11.356 24.7967 11.6231 24.9092 11.9016 24.9092H14.3516C14.63 24.9092 14.8971 24.7967 15.094 24.5965C15.2909 24.3964 15.4016 24.1248 15.4016 23.8417V16.8675C15.4012 16.5441 15.468 16.2244 15.5974 15.929C15.7268 15.6336 15.916 15.3693 16.1526 15.1533C16.3893 14.9373 16.6681 14.7744 16.9708 14.6752C17.2735 14.5761 17.5934 14.543 17.9096 14.578C18.4768 14.6504 18.9981 14.9321 19.3745 15.3695C19.7509 15.807 19.9563 16.3697 19.9516 16.9508V23.8417C19.9516 24.1248 20.0622 24.3964 20.2591 24.5965C20.456 24.7967 20.7231 24.9092 21.0016 24.9092H23.4516C23.7301 24.9092 23.9971 24.7967 24.194 24.5965C24.391 24.3964 24.5016 24.1248 24.5016 23.8417V16.8675C24.5003 15.9583 24.3226 15.0584 23.9787 14.2191C23.6348 13.3799 23.1315 12.6179 22.4975 11.9766C21.8636 11.3354 21.1114 10.8277 20.2842 10.4824C19.4569 10.1371 18.5708 9.96106 17.6766 9.9644Z"
                                            fill="white"/>
                                        <path
                                            d="M7.85 11.0316H4.4C3.90294 11.0316 3.5 11.4413 3.5 11.9466V23.9943C3.5 24.4996 3.90294 24.9093 4.4 24.9093H7.85C8.34706 24.9093 8.75 24.4996 8.75 23.9943V11.9466C8.75 11.4413 8.34706 11.0316 7.85 11.0316Z"
                                            fill="white"/>
                                        <path
                                            d="M6.125 8.8966C7.57475 8.8966 8.75 7.70176 8.75 6.22784C8.75 4.75393 7.57475 3.55908 6.125 3.55908C4.67525 3.55908 3.5 4.75393 3.5 6.22784C3.5 7.70176 4.67525 8.8966 6.125 8.8966Z"
                                            fill="white"/>
                                    </svg>
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>



            </div> : ""}
        </React.Fragment>


    )
}

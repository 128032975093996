import React, {useEffect, useState} from "react";
import {Link, useNavigate} from 'react-router-dom';
import logo from "../style/images/logo/logo.svg";
import decoration from "../style/images/img/decoration.png";
import {useTranslation} from "react-i18next";
import Slider from "../components/Slider";
import Button from "react-bootstrap/Button";
import logoMini from "../style/images/logo/logo.svg";
import {useUserContext} from "../context/UserContext";
import ForgotPasswordSuccessStatus from "../components/Status/ForgotPasswordSuccessStatus";
function decorativeDots() {
    return(
        <div className="d-flex z-0">
            <img src={decoration} alt="" className="z-0 d-flex"  style={{width:"110px", height:"110px"}}/>
            <img src={decoration} alt="" className="z-0 d-flex"  style={{width:"110px", height:"110px"}}/>
        </div>
    )
}
export default function ForgotPasswordPage() {
    const {t, i18n} = useTranslation();
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, []);
    const {forgotPassword} = useUserContext();
    const navigate = useNavigate();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isErrorSubmit, setIsErrorSubmit] = useState(false);
    const [email, setEmail] = useState('');
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [isEmailFormatInvalid, setIsEmailFormatInvalid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
//
    const handleForgotPassword = async (e) => {
        e.preventDefault();
        if (email === '') {
            setIsEmailEmpty(true);
            return;
        } else {
            setIsEmailEmpty(false);
        }
        const clearEmailError = () => {
            setIsEmailEmpty(false);
        };
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!emailPattern.test(email)) {
            setIsEmailFormatInvalid(true);
            return;
        } else {
            setIsEmailFormatInvalid(false);
        }

        try {
            await forgotPassword(email);
            setIsSuccess(true);
        } catch (error) {
            console.error('forgotPassword error:', error);
            setIsErrorSubmit(true);
        }
    };
    const clearEmailError = () => {
        setIsEmailEmpty(false);
    };
    useEffect(() => {
        if (isSuccess) {
            const redirectTimer = setTimeout(() => {
                navigate('/');
            }, 2500);
            return () => clearTimeout(redirectTimer);
        }
    }, [isSuccess, navigate]);
    return(
        <React.Fragment>
            <div className="form-page-header d-sm-flex d-none z-3">
                <div className="logo d-flex z-3">
                    <nav className="navbar pt-4 navbar-expand-lg pb-0 d-flex justify-content-start z-3">
                        <Link className="navbar-brand p-0 m-0 z-3" to="/">
                            <img src={logoMini} alt={i18n.t("pages.login.logo.alt")} className="p-0 m-0"/>
                        </Link>
                    </nav>
                </div>
            </div>
            <div className="form-page-header d-flex d-sm-none position-relative z-3">
                <div className="logo d-block position-relative  z-3">
                    <nav className="navbar pt-4 navbar-expand-lg d-flex justify-content-start ">
                        <Link className="navbar-brand" to="/">
                            <img src={logo} alt={i18n.t("pages.login.logo.alt")} className="p-0 m-0"/>
                        </Link>
                    </nav>
                </div>
            </div>
            <hr className="d-sm-none"/>
            <section className="form-page login-form-page ">
                <div className="login-form-page-content d-flex ">
                    <div className="form-page-left d-none d-md-flex h-100 form-page-half text-center">
                        <Slider/>
                        <div className="position-absolute start-0 top-0 d-flex justify-content-end"  style={{width:"50%",height:"100%"}}>{decorativeDots()}</div>
                        <div className="position-absolute start-0 top-0 d-flex justify-content-start align-items-end"  style={{width:"50%", height:"100%"}}>{decorativeDots()}</div>
                    </div>
                    <div className="form-page-right d-flex justify-content-center align-items-center">
                        <div className="form-page-right-content ">
                            {isSuccess ? <ForgotPasswordSuccessStatus title={i18n.t("pages.forget.status.success.title")} description={i18n.t("pages.forget.status.success.description")}/>
                            :
                                <>
                                    <h2 className="sec-heading m-0 ">{i18n.t("pages.forget.title")}</h2>
                                    <p className="sec-brief mt-4 mb-10">{i18n.t("pages.forget.description")}</p>
                                    <form className="login-form" onSubmit={handleForgotPassword}>
                                        <div className="form-group">
                                            <label className="form-label mb-4" htmlFor="email">{i18n.t("pages.forget.emailLabel")}</label>
                                            <input className={` ${isEmailEmpty ? 'form-control form-control-err' : 'form-control'}`}
                                                   id="email"
                                                   type="email"
                                                   value={email}
                                                   onFocus={clearEmailError}
                                                   onChange={(e) => setEmail(e.target.value)}
                                                   placeholder={i18n.t("pages.forget.emailPlaceHolder")}/>
                                                    {isEmailEmpty &&
                                                        <p className={"error-text"}>{i18n.t("pages.forget.emailEmpty")}</p>
                                                    }
                                        </div>
                                        <Button className="btn btn-primary w-100 submit-btn mt-4"
                                                type="submit">{i18n.t("pages.forget.submitButton")}</Button>
                                    </form>
                                    <div className="d-flex w-100 h-100 justify-content-end p-0 m-0">
                                        <Link to="/" className="forgot-password bg-transparent w-auto h-auto pe-0 pt-3 border-0">{i18n.t("pages.forget.turnBack")}</Link>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

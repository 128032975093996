import React, { useContext } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import ErrorPage from './components/ErrorPage';
import Page from './pages/page';
import ChangePassword from './pages/change-password';
import Login from './pages/login';
import CourseDetail from './pages/course-detail';
import Forgot from './pages/forgot-password';
import Reset from './pages/reset-password';
import { UserContext } from './context/UserContext';
function App() {
    const { token, userProfile } = useContext(UserContext);
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path='/'
                    element={token ? (userProfile?.firstLogin ? <Page /> : <ChangePassword /> ): <Login/>}
                />
                <Route
                    path="/forgot-password"
                    element={<Forgot />}
                />
                <Route
                    path="/reset-password"
                    element={ <Reset />}
                />
              {/*  <Route
                    path="/course-detail"
                    element={token ? <CourseDetail />  : <Navigate to="/" />}
                />*/}
                <Route
                    path='*'
                    element={<ErrorPage />}
                />
                <Route
                    path='/:courseId/'
                    element={ <CourseDetail /> }
                />
            </Routes>
        </BrowserRouter>
    );
}
export default App;

import i18n from "../i18n";
import {Helmet} from "react-helmet";
import React from "react";
import ChangePasswordPage from "../containers/ChangePasswordPage";
export default function Reset() {
    const TITLE = i18n.t("pages.reset.pageTitle");
    return(
        <>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <ChangePasswordPage slug={"forgot-password"} />
        </>
    )
}

import ForgotPasswordPage from "../containers/ForgotPasswordPage";
import i18n from "../i18n";
import {Helmet} from "react-helmet";
import React from "react";
export default function Forgot() {
    const TITLE = i18n.t("pages.forget.pageTitle");
    return(
        <>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <ForgotPasswordPage slug={"forgot-password"} />
        </>
    )
}

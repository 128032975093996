import React, {useState, useEffect} from "react";
import "../../style/css/status.css";
import {TiTick} from "react-icons/ti";

const SuccessStatus = ({title, description}) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsVisible(false);
        }, 2000);

        return () => clearTimeout(timeout);
    }, []);

    return isVisible ? (
        <div className={"status-content status-content-success  mb-4"}>
            <div className={"d-flex align-items-center"}>
                <h3 className={"status-content-title status-content-success-title"}>
                      <span className={"me-2"}>
                        <TiTick size={26} color={"#0642CD"}/>
                      </span>
                    {title}
                </h3>
            </div>
            <p className={"status-content-success-description"}>
                {description}
            </p>
        </div>
    ) : null;
};

export default SuccessStatus;

import axios from 'axios';


const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        "domain": window.location.hostname,
    }
});

api.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    },
);

api.interceptors.response.use(
    response => {
        return response.data || null;
    },
    error => {
        console.log(error);
        if (
            error.response.data.error === 'SESSION_NOT_FOUND' ||
            error.response.data.error === 'SESSION_EXPIRED'
        ) {
            const deleteUser = () => {
                localStorage.removeItem('token');
            };
            deleteUser();
            return Promise.reject(error.response.data.error);
        } else {
            return Promise.reject(error.response.data.error);
        }
    },
);

export default api;



import '../style/scss/courseDetail.scss';
import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import api from "../helpers/axios";
import SideBar from "../components/UserCourseDetailComponents/Sidebar";
import PageTitle from "../components/UserCourseDetailComponents/PageTitle";
import Description from "../components/UserCourseDetailComponents/CourseContent/Description";
import i18n from "../i18n";
import CourseContent from "../components/UserCourseDetailComponents/CourseContent/CourseContent";
import QuizContent from "../components/UserCourseDetailComponents/QuizContent";
import Loading from "../components/Loading/Loading";
export default function CourseDetail() {
    const [coursesDetail, setCoursesDetail] = useState([]);
    const [sideBarList, setSidebarList] = useState([]);
    const [currentContent, setCurrentContent] = useState({});
    const [loading, setLoading] = useState(true);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    //
    const location = useLocation();
    const courseId = location.pathname;


    // examResult state'ini sıfırlayan işlev
    const resetExamResult = () => {
        setShowExamResults(false)
        setUserAnswer("")
        const newSelectedOptions = new Array(coursesDetail?.questions?.length).fill(null);
        setSelectedOptions(newSelectedOptions);
    };
    //


    function getSidebarList() {
        api
            .get(`customer-courses${courseId}`, {
                headers: {
                    "domain": window.location.hostname,
                    "Content-Type": "application/json",
                }
            })
            .then(res => {
                setSidebarList(res.data);
                getCurrent();
                setLoading(false)
                console.log(res.data + " " + " sidebarlist bu")
            })
            .catch(err => {
                console.log('Error:', err.message, "Courses");
            });
    }

    function getCurrent() {
        api
            .get(`customer-courses${courseId}/current`, {
                headers: {
                    "domain": window.location.hostname,
                    "Content-Type": "application/json",
                }
            })
            .then(res => {
                setCurrentContent(res.data)
                setLoading(false)
            })
            .catch(err => {
                console.log('Error:', err.message, "Courses");
            });
    }
    useEffect(() => {
        if (courseId === "/exams/undefined" || courseId === "/contents/undefined") {
            api.get(`customer-courses${courseId}`, {
                headers: {
                    "domain": window.location.hostname,
                    "Content-Type": "application/json",
                }
            })
                .then(res => {
                    setSidebarList(res.data);
                    getCurrent();
                    setLoading(false);
                    console.log(res.data + " sidebarlist bu");

                })
                .catch(err => {
                    console.log('Error:', err.message, "Courses");
                });
        } else {
            // Normal akış, courseId tanımlı olduğunda gerçekleşir
            getSidebarList();
        }
    }, [courseId]);
    function getContentDetail(contentId) {
        api
            .get(`customer-courses${courseId}/contents/${contentId}`, {
                headers: {
                    "domain": window.location.hostname,
                    "Content-Type": "application/json",
                }
            })
            .then(res => {
                setCoursesDetail(res.data);
                setLoading(false)
            })
            .catch(err => {
                console.log('Error:', err.message, "Courses");
            });
    }

    function getExam(examId) {
        api
            .get(`customer-courses${courseId}/exams/${examId}`, {
                headers: {
                    "domain": window.location.hostname,
                    "Content-Type": "application/json",
                }
            })
            .then(res => {
                setCoursesDetail(res.data);

            })
            .catch(err => {
                console.log('Error:', err.message, "Courses");
            });
    }

    function sideBarClick(id, type) {
        if (type === 'content') {
            currentContent.contentType = "content"
            getContentDetail(id);
        } else {
            currentContent.contentType = "exam"
            getExam(id)
            resetExamResult();
        }
    }

    useEffect(() => {
        if (currentContent?.contentType === 'content') {
            getContentDetail(currentContent?.contentId)
        } else {
            getExam(currentContent?.contentId)
        }

    }, [currentContent]);
    //mobile
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const isDesktop = windowWidth >= 992;

    //coursesDetail//
    const [showExamResults, setShowExamResults] = useState(false);

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [userAnswer, setUserAnswer] = useState("");
    const [answerStatus, setAnswerStatus] = useState(null);
    const [correctAnswerOptions, setCorrectAnswerOptions] = useState([]);
    const [examResult, setExamResult] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState(
        new Array(coursesDetail?.questions?.length).fill(null)
    );
    //
    const handleNextQuestion = () => {
        if (currentQuestionIndex < coursesDetail.questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };
    const handlePreviousQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };
    const handleOptionClick = (questionIndex, optionIndex) => {
        const newSelectedOptions = [...selectedOptions];
        newSelectedOptions[questionIndex] = optionIndex;
        setSelectedOptions(newSelectedOptions);

        const questionId = coursesDetail.questions[questionIndex].id;
        const answer = coursesDetail.questions[questionIndex].type === "multiple" ? optionIndex : userAnswer;
        const updatedAnswers = [...answers];
        updatedAnswers[questionIndex] = {questionId, answer};
        setAnswers(updatedAnswers);
    };
//


let duration = coursesDetail.duration
    const [count, setCount] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            if (count < duration) {
                setCount(count + 1);
            } else {
                clearInterval(timer);
            }
        }, 60000);

        return () => {
            clearInterval(timer);
        };
    }, [count]);





    const handleSubmitAnswers = () => {
        // Kullanıcının text alanına girdiği cevabı al
        const userTextareaAnswer = userAnswer;
        // Şu anki soru için cevabı al
        const currentQuestionId = coursesDetail.questions[currentQuestionIndex].id;
        const currentQuestionOptions = coursesDetail.questions[currentQuestionIndex].options;
        // answers state'i kullanarak isteğin gövdesi
        const requestBody = {
            questionAnswer: [
                ...answers.filter((answer) => answer.answer !== ""), // Boş olmayan şıklı cevaplar
            ],
        };
        // Şıklı soruların cevapları
        const currentQuestionAnswer = currentQuestionOptions[selectedOptions[currentQuestionIndex]];
        if (currentQuestionAnswer) {
            requestBody.questionAnswer.push({questionId: currentQuestionId, answer: currentQuestionAnswer.option});
        }
        // Text alanı cevabı
        if (userTextareaAnswer.trim() !== "") {
            requestBody.questionAnswer.push({questionId: currentQuestionId, answer: userTextareaAnswer});
        }
        // POST isteği
        const examUrl = `/customer-courses${courseId}/exams/${coursesDetail.id}`;
        api
            .post(examUrl, requestBody, {
                headers: {
                    domain: window.location.hostname,
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                console.log("Cevaplar başarıyla gönderildi:", res.data);
                if (res.data) {
                    const correctAnswers = res.data.map((item) => item.correctAnswerOption);
                    setCorrectAnswerOptions(correctAnswers);
                    setExamResult(res.data)
                    setShowExamResults(true)
                }
            })
            .catch((err) => {
                console.error("Error:", err.message);
            });

    };
    // Sınav sonuçlarını almak için kullanılacak işlev
    const fetchExamResults = () => {
        const examResultsUrl = `/customer-courses${courseId}/exams/${coursesDetail.id}`;
        api
            .get(examResultsUrl, {
                headers: {
                    domain: window.location.hostname,
                },
            })
            .then((res) => {
                const response = res.data;
                console.log("Sınav Sonuçları:", response);
            })
            .catch((err) => {
                console.error("Error:", err.message);
            });
    };
    // Component ilk yüklendiğinde veya sınav sonucu gösterildiğinde sonuçları al
    useEffect(() => {
        if (answerStatus !== null) {
            fetchExamResults();
        }
    }, [answerStatus]);
    //coursesDetail
    return (
        <>
            {loading ?
                <div className={"container h-100"}>
                <div className={"row"}>
                    <div className="col-12 d-flex justify-content-center align-items-center">
                        <Loading/>
                    </div>  </div>

            </div> :
            <>
                <div className="detail">
                    <div className="courseList d-none d-xl-block d-lg-block d-md-none">
                        <PageTitle coursesDetail={coursesDetail}/>
                        <div className={"d-none d-xl-block d-lg-block d-md-none"}>
                            <SideBar sideBarList={sideBarList} sideBarClick={sideBarClick} resetExamResult={resetExamResult}
                                     coursesDetail={coursesDetail} coursesDetail={coursesDetail} userAnswer={userAnswer}
                                     setUserAnswer={setUserAnswer} currentQuestionIndex={currentQuestionIndex}
                                     showExamResults={showExamResults} examResult={examResult}
                                     selectedOptions={selectedOptions} handleOptionClick={handleOptionClick}
                                     handlePreviousQuestion={handlePreviousQuestion} handleNextQuestion={handleNextQuestion}
                                     handleSubmitAnswers={handleSubmitAnswers} count={count}/>
                        </div>
                    </div>
                    {/*desktop right*/}
                    {isDesktop &&
                        <>
                            {
                                currentContent?.contentType === 'content' ? (
                                    <div className={"content"}>
                                        <CourseContent coursesDetail={coursesDetail}/>

                                        <Description coursesDetail={coursesDetail}/>
                                    </div>
                                ) : (
                                    <QuizContent coursesDetail={coursesDetail} examResult={examResult}
                                                 sideBarList={sideBarList} sideBarClick={sideBarClick}
                                                 resetExamResult={resetExamResult} coursesDetail={coursesDetail}
                                                 coursesDetail={coursesDetail} userAnswer={userAnswer}
                                                 setUserAnswer={setUserAnswer} currentQuestionIndex={currentQuestionIndex}
                                                 showExamResults={showExamResults} examResult={examResult}
                                                 selectedOptions={selectedOptions} handleOptionClick={handleOptionClick}
                                                 handlePreviousQuestion={handlePreviousQuestion}
                                                 handleNextQuestion={handleNextQuestion}
                                                 handleSubmitAnswers={handleSubmitAnswers} count={count}
                                                 setShowExamResults={setShowExamResults} setSelectedOptions={setSelectedOptions}
                                    />
                                )
                            }
                        </>
                    }
                </div> :
                <></>
                {/*  mobile tab*/}
                <div className={"d-xl-none d-lg-none"}>
                    <div className="contentHeader mb-4">
                        <div className="topSide d-flex">
                            <div>
                                <Link className="turnBack" to="/">
                                    <div className="backButton">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                             fill="none">
                                            <path d="M19 12H5M5 12L11 18M5 12L11 6" stroke="white" strokeWidth={2}
                                                  stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <Link className="turnBack" to="/">
                                    <p className="backText text-white text-decoration-none border-none mt-1 ms-3">{i18n.t("backButton")}</p>
                                </Link>
                            </div>
                        </div>
                        <p className="Title">
                            {coursesDetail?.name}
                        </p>
                        <div className="progressComp">
                            <p className="percent">
                                {
                                    coursesDetail?.watched ? '100% Tamamlandı' : '0% Tamamlandı'
                                }
                            </p>
                            <div className={coursesDetail?.watched ? 'progressBar100' : 'progressBar0'}></div>
                        </div>
                    </div>
                    {currentContent?.contentType === 'content' &&
                        <>
                            <CourseContent coursesDetail={coursesDetail}/>
                            <div>
                                <ul className="nav nav-tabs ms-1 mt-4 mb-4" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${currentContent?.contentType === 'content' ? 'active' : 'd-none'}`}
                                            id="home-tab"
                                            data-toggle="tab"
                                            href="#home"
                                            role="tab"
                                            aria-controls="home"
                                            aria-selected="true"
                                        >
                                            {i18n.t("pages.tabs.0.title")}
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${currentContent?.contentType === 'exam' ? 'active' : ''}`}
                                            id="profile-tab"
                                            data-toggle="tab"
                                            href="#profile"
                                            role="tab"
                                            aria-controls="profile"
                                            aria-selected="false"
                                        >
                                            {i18n.t("pages.tabs.1.title")}
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div
                                        className={`tab-pane fade ${currentContent?.contentType === 'content' ? 'show active' : ''}`}
                                        id="home"
                                        role="tabpanel"
                                        aria-labelledby="home-tab"
                                    >
                                        <div className="detail">
                                            <div className="courseList ">
                                                <div className="searchField" style={{marginBottom:"-40px"}}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path
                                                            d="M20.71 19.29L17.31 15.9C18.407 14.5025 19.0022 12.7767 19 11C19 9.41775 18.5308 7.87103 17.6518 6.55544C16.7727 5.23985 15.5233 4.21447 14.0615 3.60897C12.5997 3.00347 10.9911 2.84504 9.43928 3.15372C7.88743 3.4624 6.46197 4.22433 5.34315 5.34315C4.22433 6.46197 3.4624 7.88743 3.15372 9.43928C2.84504 10.9911 3.00347 12.5997 3.60897 14.0615C4.21447 15.5233 5.23985 16.7727 6.55544 17.6518C7.87103 18.5308 9.41775 19 11 19C12.7767 19.0022 14.5025 18.407 15.9 17.31L19.29 20.71C19.3832 20.8032 19.4939 20.8772 19.6158 20.9277C19.7376 20.9781 19.8681 21.0041 20 21.0041C20.1319 21.0041 20.2624 20.9781 20.3843 20.9277C20.5061 20.8772 20.6168 20.8032 20.71 20.71C20.8032 20.6168 20.8772 20.5061 20.9277 20.3843C20.9781 20.2624 21.0041 20.1319 21.0041 20C21.0041 19.8681 20.9781 19.7376 20.9277 19.6158C20.8772 19.4939 20.8032 19.3832 20.71 19.29ZM5 11C5 9.81332 5.3519 8.65328 6.01119 7.66658C6.67047 6.67989 7.60755 5.91085 8.7039 5.45673C9.80026 5.0026 11.0067 4.88378 12.1705 5.11529C13.3344 5.3468 14.4035 5.91825 15.2426 6.75736C16.0818 7.59648 16.6532 8.66558 16.8847 9.82946C17.1162 10.9933 16.9974 12.1997 16.5433 13.2961C16.0892 14.3925 15.3201 15.3295 14.3334 15.9888C13.3467 16.6481 12.1867 17 11 17C9.4087 17 7.88258 16.3679 6.75736 15.2426C5.63214 14.1174 5 12.5913 5 11Z"
                                                            fill="#928F9A"/>
                                                    </svg>
                                                    <input className="search" type="text" placeholder="Ara"/>
                                                </div>
                                            </div>
                                        </div>




                                        {currentContent?.contentType === 'content' &&
                                            <Description coursesDetail={coursesDetail}/>}

                                    </div>
                                    <div
                                        className={`tab-pane fade ${currentContent?.contentType === 'exam' ? 'show active' : ''}`}
                                        id="profile"
                                        role="tabpanel"
                                        aria-labelledby="profile-tab"
                                    >
                                        <div>
                                            <SideBar sideBarList={sideBarList} sideBarClick={sideBarClick}
                                                     resetExamResult={resetExamResult} coursesDetail={coursesDetail}
                                                     coursesDetail={coursesDetail} userAnswer={userAnswer}
                                                     setUserAnswer={setUserAnswer} currentQuestionIndex={currentQuestionIndex}
                                                     showExamResults={showExamResults} examResult={examResult}
                                                     selectedOptions={selectedOptions} handleOptionClick={handleOptionClick}
                                                     handlePreviousQuestion={handlePreviousQuestion}
                                                     handleNextQuestion={handleNextQuestion}
                                                     handleSubmitAnswers={handleSubmitAnswers} count={count}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>

                    }
                    {currentContent?.contentType === 'exam' &&
                        <>
                            <QuizContent coursesDetail={coursesDetail} sideBarList={sideBarList} sideBarClick={sideBarClick}
                                         resetExamResult={resetExamResult} coursesDetail={coursesDetail}
                                         coursesDetail={coursesDetail} userAnswer={userAnswer} setUserAnswer={setUserAnswer}
                                         currentQuestionIndex={currentQuestionIndex} showExamResults={showExamResults}
                                         examResult={examResult} selectedOptions={selectedOptions}
                                         handleOptionClick={handleOptionClick} handlePreviousQuestion={handlePreviousQuestion}
                                         handleNextQuestion={handleNextQuestion} handleSubmitAnswers={handleSubmitAnswers}
                                         count={count}
                                         setShowExamResults={setShowExamResults} setSelectedOptions={setSelectedOptions} currentContent={currentContent} sideBarList={sideBarList} sideBarClick={sideBarClick}
                            />
                        </>
                    }
                </div>
            </>
            }
        </>
    )
}


import React from 'react';
import Header from '../components/Header';
import ContactForm from '../components/ContactForm';
import UserCourses from '../components/UserCourses';
export default function Page() {
    return (
        <React.Fragment>
                    <Header />
                    <UserCourses />
                    <ContactForm />
        </React.Fragment>
    );
}

import React from "react";
import {Link} from "react-router-dom";
import i18n from "../../i18n";

export default function PageTitle({coursesDetail}) {
    return (
        <>
            <div className="contentHeader">
                <div className="topSide">
                    <Link className="turnBack" to="/">
                        <div className="backButton">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none">
                                <path d="M19 12H5M5 12L11 18M5 12L11 6" stroke="white" strokeWidth={2}
                                      stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <p className="backText">{i18n.t("backButton")}</p>
                    </Link>
                    <p className="Title">
                        {coursesDetail?.name}
                    </p>
                </div>
                <div className="progressComp">
                    <p className="percent">
                        {
                            coursesDetail?.watched ? '100% Tamamalandı' : '0% Tamamlandı'
                        }
                    </p>
                    <div className={coursesDetail?.watched ? 'progressBar100' : 'progressBar0'}></div>
                </div>
            </div>
            <div className="searchField">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M20.71 19.29L17.31 15.9C18.407 14.5025 19.0022 12.7767 19 11C19 9.41775 18.5308 7.87103 17.6518 6.55544C16.7727 5.23985 15.5233 4.21447 14.0615 3.60897C12.5997 3.00347 10.9911 2.84504 9.43928 3.15372C7.88743 3.4624 6.46197 4.22433 5.34315 5.34315C4.22433 6.46197 3.4624 7.88743 3.15372 9.43928C2.84504 10.9911 3.00347 12.5997 3.60897 14.0615C4.21447 15.5233 5.23985 16.7727 6.55544 17.6518C7.87103 18.5308 9.41775 19 11 19C12.7767 19.0022 14.5025 18.407 15.9 17.31L19.29 20.71C19.3832 20.8032 19.4939 20.8772 19.6158 20.9277C19.7376 20.9781 19.8681 21.0041 20 21.0041C20.1319 21.0041 20.2624 20.9781 20.3843 20.9277C20.5061 20.8772 20.6168 20.8032 20.71 20.71C20.8032 20.6168 20.8772 20.5061 20.9277 20.3843C20.9781 20.2624 21.0041 20.1319 21.0041 20C21.0041 19.8681 20.9781 19.7376 20.9277 19.6158C20.8772 19.4939 20.8032 19.3832 20.71 19.29ZM5 11C5 9.81332 5.3519 8.65328 6.01119 7.66658C6.67047 6.67989 7.60755 5.91085 8.7039 5.45673C9.80026 5.0026 11.0067 4.88378 12.1705 5.11529C13.3344 5.3468 14.4035 5.91825 15.2426 6.75736C16.0818 7.59648 16.6532 8.66558 16.8847 9.82946C17.1162 10.9933 16.9974 12.1997 16.5433 13.2961C16.0892 14.3925 15.3201 15.3295 14.3334 15.9888C13.3467 16.6481 12.1867 17 11 17C9.4087 17 7.88258 16.3679 6.75736 15.2426C5.63214 14.1174 5 12.5913 5 11Z"
                        fill="#928F9A"/>
                </svg>
                <input className="search" type="text" placeholder="Ara"/>
            </div>
        </>
    );
}

import logoWhite from "../style/images/logo/logo.svg";
import logoMini from "../style/images/logo/logo.svg";
import '../style/fonts/stylesheet.css';
import '../style/vendor/bootstrap/bootstrap.min.css';
import '../style/vendor/slick/slick.css';
import '../style/vendor/slick/slick-theme.css';
import '../style/scss/teacher-profile.scss';
import {Dropdown, Nav, Navbar, NavbarBrand, NavDropdown} from "react-bootstrap";
import {useContext} from "react";
import {UserContext} from "../context/UserContext";
import i18n from "../i18n";
import Button from "react-bootstrap/Button";

function chevronDown() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path
                d="M12.7 15.9C12.5683 15.9008 12.4379 15.8755 12.316 15.8258C12.1942 15.776 12.0834 15.7027 11.99 15.61L7.98995 11.61C7.80165 11.4217 7.69586 11.1663 7.69586 10.9C7.69586 10.6337 7.80165 10.3783 7.98995 10.19C8.17826 10.0017 8.43365 9.8959 8.69995 9.8959C8.96626 9.8959 9.22165 10.0017 9.40995 10.19L12.7 13.5L16 10.32C16.0919 10.2177 16.2041 10.1355 16.3293 10.0785C16.4545 10.0215 16.5901 9.99099 16.7277 9.98881C16.8652 9.98663 17.0017 10.0129 17.1287 10.0658C17.2556 10.1188 17.3703 10.1975 17.4655 10.2968C17.5607 10.3961 17.6343 10.514 17.6819 10.6431C17.7294 10.7722 17.7498 10.9097 17.7418 11.047C17.7338 11.1844 17.6975 11.3186 17.6352 11.4413C17.573 11.5639 17.4861 11.6724 17.38 11.76L13.38 15.62C13.1971 15.7963 12.954 15.8964 12.7 15.9Z"
                fill="#103F9F"/>
        </svg>
    )
}

export default function Header() {

    const {logOut, userProfile,site} = useContext(UserContext);
    const handleLogout = async (e) => {
        e.preventDefault();

        try {
            await logOut();
            console.log("Başarılı")
        } catch (error) {
            console.error('Başarısız:', error);

        }
    };

    return (
        userProfile &&

        <>
            <div className="navtop">
                <Navbar className="container d-md-flex d-none text-white p-0">
                    {site &&
                    <NavbarBrand className="navbar-brand d-flex text-white " href="">
                        <img src={site.logo} alt="" height={50}/>
                    </NavbarBrand>}
                    <Navbar.Collapse className=" d-flex" id="basic-navbar-nav">
                        <Nav className=" d-flex justify-content-end p-0 m-0 align-items-center">
                            <NavDropdown className="d-flex" title={userProfile?.name} id="basic-nav-dropdown">
                                <NavDropdown.Item href="/" onClick={handleLogout}>
                                    {i18n.t("pages.account.logOut")}
                                </NavDropdown.Item>
                            </NavDropdown>
                            {chevronDown()}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
            <div className="container pe-4 ps-4 d-xl-none d-lg-none "  style={{marginTop:"-80px"}}>
            <div className={" "}>
                <div className="d-flex justify-content-between">
                    {site &&
                    <div><img src={site.logo} alt="" height={50}/></div>}
                    <div>
                        <Dropdown>
                        <Dropdown.Toggle variant="" id="dropdown-basic">
                                <div className={"mobile-user-name"}>{userProfile?.name}     {chevronDown()}</div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="/"
                                               onClick={handleLogout}>            {i18n.t("pages.account.logOut")}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            </div>
        </>

    )
}

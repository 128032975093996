import React, {useContext, useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import "../style/scss/form-page.scss";
import "../style/fonts/stylesheet.css";
import "../style/vendor/bootstrap/bootstrap.min.css";
import logoLight from "../style/images/logo/logo-light.svg";
import logoMini from "../style/images/logo/logo.svg";
import {UserContext} from "../context/UserContext";
import decoration from "../style/images/img/decoration.png";
import Slider from "../components/Slider";
import Button from "react-bootstrap/Button";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import SuccessStatus from "../components/Status/SuccessStatus";
import ErrorStatus from "../components/Status/ErrorStatus";
import Loading from "../components/Loading/Loading";
import {BiError} from "react-icons/bi";
function decorativeDots() {
    return (
        <div className="d-flex flex z-0">
            <img src={decoration} alt="" className="z-0 d-flex" style={{width: "90px", height: "90px"}}/>
            <img src={decoration} alt="" className="z-0 d-flex" style={{width: "90px", height: "90px"}}/>
        </div>
    )
}
const LoginPage = () => {
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 0);
    }, []);
    
   
    const { i18n} = useTranslation();
    const {login, status, isLoginSuccessful, enterPassword, setEnterPassword, site} = useContext(UserContext);
    //
    const [isLoading, setIsLoading] = useState(true);
    //
    const [tcNumber, setTcNumber] = useState('');
    const [password, setPassword] = useState('');
    //
    const [isTcNumberEmpty, setIsTcNumberEmpty] = useState(false);
    const [isPasswordEmpty, setIsPasswordEmpty] = useState(false);
    const [isInputsEmpty, setIsInputsEmpty] = useState(false);
    //

    const [isPasswordLengthError, setIsPasswordLengthError] = useState(false);
    //
    const [showPassword, setShowPassword] = useState(false);
    const handleLogin = async (e) => {
        e.preventDefault();
        setTcNumber(tcNumber.trim());
        setPassword(password.trim());
        if (tcNumber === '' && (password === '' || isPasswordLengthError) ) {
            setIsInputsEmpty(true);
            return
        } else {
            setIsInputsEmpty(false);
        }

        if (tcNumber === '') {
            setIsTcNumberEmpty(true);
            return;
        } else {
            setIsTcNumberEmpty(false);
        }
        if ( password === '') {
            setIsPasswordEmpty(true);
            return;
        } else {
            setIsPasswordEmpty(false);
        }

        if ( password.length < 3) {
            setIsPasswordLengthError(true);
            return;
        } else {
            setIsPasswordLengthError(false);
        }
        try {
            const status = await login(tcNumber, password);
            if(status) console.log("Başarılı")
            else console.log("Başarısız")
        } catch (error) {
            console.error('Başarısız:', error);
        }
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const clearTcNumberError = () => {
        setIsInputsEmpty(false);
        setIsTcNumberEmpty(false);
    };
    const clearPasswordError = () => {
        setIsInputsEmpty(false);
        setIsPasswordEmpty(false);
    };
    const renderSuccessMessage = () => {
        if (isLoginSuccessful) {
            return <SuccessStatus title={i18n.t("pages.login.status.success.title")}
                                  description={i18n.t("pages.login.status.success.description")}/>
        }
    };
    const renderErrorMessage = () => {
    
    };
    useEffect(() => {
        console.log("1", status)
    }, [status]);
    return (
        <>
            {isLoading ?
               <>
               <Loading/>
               </>
                :
                <div className="d-flex wrapper flex-column">
                    <div className="position-absolute start-0 top-0 d-md-flex justify-content-end dots"
                         style={{width: "50%", height: "100%"}}>{decorativeDots()}</div>
                    <div
                        className="position-absolute start-0 top-0 d-md-flex justify-content-start align-items-end dots"
                        style={{width: "50%", height: "100%"}}>{decorativeDots()}</div>
                    <div className="form-page-header  z-3">
                        <div className="logo d-flex z-3">
                            {site &&
                            <nav className="navbar pt-4 navbar-expand-lg d-flex justify-content-start z-3">
                                
                                <Link className="navbar-brand p-0 m-0 z-3 d-none d-xl-block d-lg-block d-md-none"
                                      to="/">
                                    <img src={site.logo} alt={i18n.t("pages.login.logo.alt")} className="p-0 m-0" height={50}/>
                                </Link>
                                <Link className="navbar-brand d-xl-none d-lg-none" to="/">
                                    <img src={site.logo} alt={i18n.t("pages.login.logo.alt")} className="p-0 m-0" height={50}/>
                                </Link>
                            </nav> }
                        </div>
                    </div>
                    <hr className="divided d-md-none"/>
                    <section className="form-page login-form-page">
                        <div className="login-form-page-content d-flex ">
                            <div
                                className="form-page-left d-none d-md-flex h-100 form-page-half align-items-center justify-content-center text-center">
                                <Slider />
                            </div>
                            <div className="form-page-right d-flex justify-content-center align-items-center">
                                <div className="form-page-right-content ">
                                    <h2 className="sec-heading m-0 ">{i18n.t("pages.login.form.title")}</h2>
                                    <p className="sec-brief mt-4 mb-10">{i18n.t("pages.login.form.subTitle")}</p>
                                    <form className="login-form" onSubmit={handleLogin}>
                                        <div>{
                                             <ErrorStatus title={i18n.t("pages.login.status.error.title")} description={i18n.t("pages.login.status.error.description")} status={!status}/>
                                        }</div>
                                        <div>{renderSuccessMessage()}</div>
                                        <>
                                            <div className="form-group mb-4">
                                                <label className="form-label mb-4"
                                                       htmlFor="tcNumber">{i18n.t("pages.login.form.tcNumber")}</label>
                                                <input
                                                    className={`form-control ${isTcNumberEmpty || isInputsEmpty  ? 'form-control-err' : ''}`}
                                                    id="tcNumber"
                                                    type="text"
                                                    value={tcNumber}
                                                    onFocus={() => {
                                                        clearTcNumberError();
                                                    }}
                                                    onChange={(e) => {
                                                        const inputText = e.target.value;
                                                        if (/^\d*$/.test(inputText)) {
                                                            setTcNumber(inputText);
                                                        }
                                                    }}
                                                    placeholder={i18n.t("pages.login.form.tcNumberPlaceHolder")}
                                                />

                                                {isTcNumberEmpty || isInputsEmpty &&
                                                    <p className={"error-text"}>{i18n.t("pages.login.form.tcNumberEmpty")}</p>
                                                }
                                            </div>
                                            <div className="form-group">
                                                <div className="d-flex labels justify-content-between ">
                                                    <label className="form-label mb-4 "
                                                           htmlFor="password">{i18n.t("pages.login.form.password")}</label>
                                                   {/* <Link to="/forgot-password"
                                                          className="forgot-password bg-transparent border-0">
                                                        {i18n.t("pages.login.form.forgetPassword")}
                                                    </Link>*/}
                                                </div>
                                                <div
                                                  className="password-wrap align-items-center justify-content-center position-relative">
                                                    <input
                                                      className={` ${isPasswordEmpty || isInputsEmpty || isPasswordLengthError ? 'form-control form-control-err' : 'form-control'}`}
                                                      id="password"
                                                        type={showPassword ? "text" : "password"}
                                                        value={password}
                                                        onFocus={clearPasswordError} tabIndex={2}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        placeholder={i18n.t("pages.login.form.passwordPlaceholder")}/>
                                                    {isPasswordEmpty    &&
                                                        <p className={"error-text"}>{i18n.t("pages.login.form.passwordEmpty")}</p>
                                                    }
                                                    {isInputsEmpty &&
                                                        <p className={"error-text"}>{i18n.t("pages.login.form.passwordEmpty")}</p>
                                                    }
                                                    <button
                                                        tabIndex={3}
                                                        id="showPassword"
                                                        type="button"
                                                        className="bg-transparent position-absolute border-0 d-flex justify-content-center align-items-center p-0 m-0 w-auto h-auto"
                                                        style={{
                                                            right: "16px",
                                                            top: "16px"
                                                        }}
                                                        onClick={togglePasswordVisibility}
                                                    >
                                                        {showPassword ? <FaEye color={'#cac6d4'} size={24}/> :
                                                            <FaEyeSlash color={'#cac6d4'} size={24}/>}
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                        <Button className="btn btn-primary w-100 submit-btn mt-4"
                                                type="submit" >
                                                { i18n.t("pages.login.form.button")}
                                        </Button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>}
        </>
    )
}

export default LoginPage;

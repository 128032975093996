import React, { useState} from "react";
import i18n from "../../../i18n";
export default function Description({coursesDetail}) {
//documents copied
    const [copied, setCopied] = useState(false);

    const fileUrl = coursesDetail.file;

    const copyToClipboard = () => {
        const el = document.createElement("textarea");
        el.value = fileUrl;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);

        setCopied(true);

        setTimeout(() => {
            setCopied(false);
        }, 3000);
    };
    return (
<React.Fragment>
    <div className="description">
        <div className="overview">
            <div dangerouslySetInnerHTML={{__html: coursesDetail.description}}/>
        </div>
    </div>
    <div className="bottomSlider mb-5 pb-5">
        <div className="sameContent">
            <div className="navStick">
                <p className="Title">{i18n.t("pages.content.documents")}</p>
                <div className="buttons">
                    <button className="previous">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24"
                             className="leftArrow">
                            <path d="M19 12H5M5 12L11 18M5 12L11 6" strokeWidth="2"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                    <button id="buttonRight" className="next">
                        <svg xmlns="http://www.w3.org/2000/svg" className="rightArrow" width="24"
                             height="24"
                             viewBox="0 0 24 24">
                            <path d="M5 12H19M19 12L13 6M19 12L13 18" strokeWidth="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div id="container" className="cards">
                <div className="sameCard">
                    <img src={coursesDetail.image} alt=""/>
                    <div className="title">
                        <svg width="32" height="40" viewBox="0 0 32 40" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.01334 24.7271C7.647 24.7271 7.40013 24.7662 7.27271 24.8053V27.3665C7.42402 27.4056 7.61316 27.4165 7.87397 27.4165C8.82763 27.4165 9.41496 26.8903 9.41496 26.0011C9.41496 25.2054 8.90926 24.7271 8.01334 24.7271ZM14.9558 24.7531C14.5576 24.7531 14.2988 24.7923 14.1455 24.8314V30.506C14.2988 30.5451 14.5456 30.5451 14.7686 30.5451C16.3952 30.5581 17.4544 29.5798 17.4544 27.51C17.4664 25.7054 16.5007 24.7531 14.9558 24.7531Z"
                                fill="#FC196E"/>
                            <path
                                d="M20 0H4C2.93913 0 1.92172 0.413765 1.17157 1.15027C0.421427 1.88678 0 2.8857 0 3.92727V35.3455C0 36.387 0.421427 37.3859 1.17157 38.1225C1.92172 38.859 2.93913 39.2727 4 39.2727H28C29.0609 39.2727 30.0783 38.859 30.8284 38.1225C31.5786 37.3859 32 36.387 32 35.3455V11.7818L20 0ZM10.996 27.864C10.378 28.4335 9.466 28.6887 8.404 28.6887C8.19808 28.6909 7.99226 28.6791 7.788 28.6534V31.4535H6V23.7247C6.80691 23.6065 7.62224 23.5526 8.438 23.5636C9.552 23.5636 10.344 23.7718 10.878 24.19C11.386 24.5867 11.73 25.2367 11.73 26.0025C11.728 26.7722 11.468 27.4222 10.996 27.864ZM18.61 30.5247C17.77 31.21 16.492 31.536 14.93 31.536C13.994 31.536 13.332 31.4771 12.882 31.4182V23.7266C13.6892 23.6109 14.5043 23.5565 15.32 23.5636C16.834 23.5636 17.818 23.8307 18.586 24.4001C19.416 25.0049 19.936 25.9691 19.936 27.3535C19.936 28.8517 19.378 29.8865 18.61 30.5247ZM26 25.0756H22.936V26.8645H25.8V28.3058H22.936V31.4555H21.124V23.6225H26V25.0756ZM20 13.7455H18V3.92727L28 13.7455H20Z"
                                fill="#FC196E"/>
                        </svg>

                        <p className="inlineTitle">{coursesDetail.name}</p>
                    </div>
                  {/*  <div className="miniInfos">
                        <p className="size">1 GB</p>
                        <p className="date">19 Nis 2023</p>
                    </div>*/}
                    <div className={"d-flex justify-content-between mt-3"}>
                        <div>
                            <a href={coursesDetail?.file}
                               download>
                                <button
                                    className="btn btn-pink text-white">{i18n.t("pages.content.download")}
                                </button>
                            </a>
                        </div>
                        <div>
                            {coursesDetail?.file ? <button className="btn btn-blue text-white"
                                                           onClick={copyToClipboard}>
                                Paylaş
                            </button> : <button className="btn btn-blue text-white"
                                                onClick={copyToClipboard}>
                               Kopyalandı
                            </button>}
                            {copied && (
                                <div
                                    style={{color: "green"}}>   {i18n.t("pages.content.copied")}</div>
                            )}
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</React.Fragment>
    )
}

import React, {useEffect, useState} from "react";
import '../../style/scss/quizPage.scss';
import {Form} from "react-bootstrap";
import i18n from "../../i18n";
import Description from "./CourseContent/Description";
import SideBar from "./Sidebar";


export default function QuizContent({
                                        coursesDetail,
                                        userAnswer,
                                        setUserAnswer,
                                        currentQuestionIndex,
                                        showExamResults,
                                        examResult,
                                        selectedOptions,
                                        handleOptionClick,
                                        handlePreviousQuestion,
                                        handleNextQuestion,
                                        handleSubmitAnswers,
                                        count,
                                        resetExamResult,
                                        currentContent,
                                        sideBarClick,
                                        sideBarList
                                    }) {
    let quiz = coursesDetail
//
    const [isQuizStarted, setIsQuizStarted] = useState(false);
    //
    const startQuiz = () => {
        setIsQuizStarted(true);
    };

    const renderQuizContent = () => {
        if (!isQuizStarted) {
            return (
                <div className={"row mt-5"}>
                    <div className="col-12 d-flex justify-content-center align-items-center">
                        <button onClick={startQuiz} className={"btn-quiz-start btn btn-primary pt-3"}>
                           <p className={"btn-quiz-start-text"}>{i18n.t("pages.quiz.button")}</p>
                        </button>
                    </div>
                </div>

            );
        }
    };
    //
    const renderAnswerStatus = (item) => {
        if (item.answerStatus === true) {
            return (
                <>
                    {item.type === "multiple" && (
                        <>
                            {item.options?.map((option, optionIndex) => (
                                <li
                                    className={`lesson-page-question  ${
                                        option.correctAnswerOption ? "correctAnswerOption" : ""
                                    }`}
                                    key={option.id}
                                >
                                    <div className={"lesson-page-question-title"}>{option.option}</div>
                                </li>
                            ))}
                        </>
                    )}
                    {item.type === "single" && (
                        <>
                            <Form>
                                <Form.Group className="mb-3 mt-4"
                                            controlId={item.id}>
                                    <Form.Control
                                        disabled={true}
                                        className="lesson-page-textarea lesson-page-textarea-border"
                                        as="textarea"
                                        placeholder="Yanıtınızı giriniz"
                                        rows={3}
                                        value={userAnswer}
                                        onChange={(e) => setUserAnswer(e.target.value)}
                                    />
                                    <div className="col-12  align-items-center mt-2"
                                         style={{color: "#36D6BA"}}>
                                        {i18n.t("pages.quiz.form.succesText")}
                                    </div>
                                </Form.Group>
                            </Form>
                        </>
                    )}
                </>
            );
        } else {
            const incorrectAnswerIndex = parseInt(item.customerAnswer, 10);
            return (
                <div>
                    {item.type === "multiple" && (
                        <>
                            {item.options?.map((option, optionIndex) => (
                                <li
                                    className={`lesson-page-question mb-4 ${
                                        optionIndex === incorrectAnswerIndex ? "notCorrectAnswerOption" : ""
                                    } ${option.correctAnswerOption ? "correctAnswerOption" : ""}`}
                                    key={option.id}
                                >
                                    <div className={"lesson-page-question-title"}>{option.option}</div>
                                </li>
                            ))}
                        </>
                    )}
                    {item.type === "single" && (
                        <>
                            <Form>
                                <Form.Group className="mb-3 mt-4"
                                            controlId={item.id}>
                                    <Form.Control
                                        disabled={true}
                                        className="lesson-page-textarea lesson-page-textarea-border"
                                        as="textarea"
                                        placeholder={i18n.t("pages.quiz.form.textareaPlaceholder")}
                                        rows={3}
                                        value={userAnswer}
                                        onChange={(e) => setUserAnswer(e.target.value)}
                                    />
                                    <div className="col-12  align-items-center mt-2"
                                         style={{color: "#F56962"}}>
                                        {i18n.t("pages.quiz.form.errorText")}
                                    </div>
                                </Form.Group>
                            </Form>
                        </>
                    )}
                </div>
            );
        }
    };
    return (
        <>
       <div className="content ps-4 pe-4 ps-xl-4 ps-lg-4 pe-xl-3 pe-lg-3">
           {!isQuizStarted &&
               <div className="head" style={{marginLeft: "-50px"}}>
                   <div className="texts">
                       <p className="Title">{quiz.name}</p>
                       <p className="during ">{quiz.duration}&nbsp; {i18n.t("pages.content.duration")}</p>
                   </div>
               </div>
           }

                <div className="exam">

                        <div className="headerInfo">
                            <p className="Description">{quiz.description}</p>
                        </div>

                   {/* mobile*/}
                    {isQuizStarted &&
                        <>
                            <div style={{marginBottom: "-50px"}} className={"d-xl-none d-lg-none"}></div>
                            <div className="info">
                                <div className="questionNumber">
                                    {currentQuestionIndex + 1} / {quiz.questions?.length}
                                </div>
                                <div className="seperator"></div>
                                <div className="time">
                                  {/* <span className={"questionDurationTitle"}>{i18n.t("pages.quiz.questionDurationTitle")}</span> &nbsp; */} {count} &nbsp; <span className={"questionDuration"}>{i18n.t("pages.quiz.questionDuration")}</span>
                                </div>
                            </div>
                        </>
                    }

                    {/*desktop*/}
                    <>

                        <div tyle={{marginTop: "-50px"}} className={"d-none d-xl-block d-lg-block d-md-none"}>
                        <div className="info">
                            <div className="questionNumber">
                                {currentQuestionIndex + 1} / {quiz.questions?.length} &nbsp;{i18n.t("pages.quiz.questionTitle")}
                            </div>
                            <div className="seperator"></div>
                            <div className="time">
                                {i18n.t("pages.quiz.questionDurationTitle")}   {count} {i18n.t("pages.quiz.questionDuration")}
                            </div>
                        </div>
                        </div>
                    </>





                    {examResult?.length >= 1 && (
                        <div className={"question-post-scroll mt-4 mb-5 p-2"}>
                            {examResult?.map((item, index) => (
                                <div className="question mt-5" key={index}>
                                    <p className="questionDesc" id="demo-radio-buttons-group-label"
                                       style={{display: index === currentQuestionIndex ? 'block' : 'block'}}
                                    >  {index + 1}. {item.name}</p>
                                    <ul className="ansfers active">
                                        {renderAnswerStatus(item)}
                                    </ul>
                                </div>
                            ))}
                        </div>

                    )}
                    <div className={"d-xl-none d-lg-none"}>
                        {renderQuizContent()}
                    </div>


                {/*desktop exam */}
                    {examResult?.length <= 1   &&
                        <div className={"d-none d-xl-block d-lg-block"}>
                            <div className={" mt-4"}>
                                {quiz?.questions?.map((item, index) => {
                                    return (
                                        <>
                                            <div className=" question mt-5" id="demo-radio-buttons-group-label"
                                                 key={index}
                                                 style={{display: index === currentQuestionIndex ? 'block' : 'none'}}
                                            ><p className={"questionDesc"}>{index + 1}. {item.name}</p>
                                                <ul className="ansfers active">
                                                    <>
                                                        {item.type === "multiple" && (
                                                            <>
                                                                {item.options?.map((option, optionIndex) => {
                                                                    const isSelected =
                                                                        selectedOptions[index] === optionIndex;
                                                                    return (
                                                                        <li
                                                                            className={`lesson-page-question ${
                                                                                isSelected ? "selected-option" : ""
                                                                            }`}
                                                                            key={option.id}
                                                                            onClick={() =>
                                                                                handleOptionClick(index, optionIndex)
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={"lesson-page-question-title"}>
                                                                                {option.option}
                                                                            </div>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </>
                                                        )}
                                                        {item.type === "single" && (
                                                            <>
                                                                <Form key={index}>
                                                                    <Form.Group className="mb-3 mt-4"
                                                                                controlId={item.id}>
                                                                        <Form.Control
                                                                            className="lesson-page-textarea"
                                                                            as="textarea"
                                                                            placeholder={i18n.t("pages.quiz.form.textareaPlaceholder")}
                                                                            rows={3}
                                                                            value={userAnswer}
                                                                            onChange={(e) => setUserAnswer(e.target.value)}
                                                                        />
                                                                    </Form.Group>
                                                                </Form>
                                                            </>
                                                        )}
                                                    </>
                                                </ul>
                                            </div>
                                        </>
                                    )
                                })}
                                <div className={"row mt-4"}>
                                    <div className="col-12 d-flex justify-content-between">
                                        <div>
                                            <button className={"btn exam-prev-btn mt-3"}
                                                    onClick={handlePreviousQuestion}
                                                    disabled={currentQuestionIndex === 0}>
                                                <p className={"exam-prev-btn-text"}> {i18n.t("pages.quiz.form.prevButton")}</p>
                                            </button>
                                        </div>
                                        <div>
                                            {currentQuestionIndex !== quiz.questions?.length - 1 && (
                                                <button className={"exam-next-btn mt-3 mb-5"}
                                                        onClick={handleNextQuestion}>
                                                    <p className={"exam-next-btn-text"}> {i18n.t("pages.quiz.form.nextButton")}</p>
                                                </button>
                                            )}

                                            {currentQuestionIndex === quiz.questions?.length - 1 && (
                                                <button
                                                    className={"btn exam-submit-btn mt-3 mb-5"}
                                                    onClick={handleSubmitAnswers}
                                                >
                                                    <p className={"exam-next-btn-text"}>{i18n.t("pages.quiz.form.submitButtonText")}</p>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                  {/*  mobile exam*/}
                    {examResult?.length <= 1 && isQuizStarted  &&
                        <div className={"d-xl-none d-lg-none"}>
                            <div className={" mt-4"}>
                                {quiz?.questions?.map((item, index) => {
                                    return (
                                        <>
                                            <div className=" question mt-5" id="demo-radio-buttons-group-label"
                                                 key={index}
                                                 style={{display: index === currentQuestionIndex ? 'block' : 'none'}}
                                            ><p className={"questionDesc"}>{index + 1}. {item.name}</p>



                                                <ul className="ansfers active">
                                                    <>
                                                        {item.type === "multiple" && (
                                                            <>
                                                                {item.options?.map((option, optionIndex) => {
                                                                    const isSelected =
                                                                        selectedOptions[index] === optionIndex;
                                                                    return (
                                                                        <li
                                                                            className={`lesson-page-question ${
                                                                                isSelected ? "selected-option" : ""
                                                                            }`}
                                                                            key={option.id}
                                                                            onClick={() =>
                                                                                handleOptionClick(index, optionIndex)
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={"lesson-page-question-title"}>
                                                                                {option.option}
                                                                            </div>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </>
                                                        )}
                                                        {item.type === "single" && (
                                                            <>
                                                                <Form key={index}>
                                                                    <Form.Group className="mb-3 mt-4"
                                                                                controlId={item.id}>
                                                                        <Form.Control
                                                                            className="lesson-page-textarea"
                                                                            as="textarea"
                                                                            placeholder={i18n.t("pages.quiz.form.textareaPlaceholder")}
                                                                            rows={3}
                                                                            value={userAnswer}
                                                                            onChange={(e) => setUserAnswer(e.target.value)}
                                                                        />
                                                                    </Form.Group>
                                                                </Form>
                                                            </>
                                                        )}
                                                    </>
                                                </ul>
                                            </div>
                                        </>
                                    )
                                })}
                                <div className={"row mt-4"}>
                                    <div className="col-12 d-flex justify-content-between">
                                        <div>
                                            <button className={"btn exam-prev-btn mt-3"}
                                                    onClick={handlePreviousQuestion}
                                                    disabled={currentQuestionIndex === 0}>
                                                <p className={"exam-prev-btn-text"}> {i18n.t("pages.quiz.form.prevButton")}</p>
                                            </button>
                                        </div>
                                        <div>
                                            {currentQuestionIndex !== quiz.questions?.length - 1 && (
                                                <button className={"exam-next-btn mt-3 mb-5"}
                                                        onClick={handleNextQuestion}>
                                                    <p className={"exam-next-btn-text"}> {i18n.t("pages.quiz.form.nextButton")}</p>
                                                </button>
                                            )}

                                            {currentQuestionIndex === quiz.questions?.length - 1 && (
                                                <button
                                                    className={"btn exam-submit-btn mt-3 mb-5"}
                                                    onClick={handleSubmitAnswers}
                                                >
                                                    <p className={"exam-next-btn-text"}>{i18n.t("pages.quiz.form.submitButtonText")}</p>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {/*  mobile tab*/}
                    {!isQuizStarted &&

                    <>
                        <div className={"d-xl-none d-lg-none"}>
                            <ul className="nav nav-tabs ms-1 mt-4 mb-4" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${currentContent?.contentType === 'content' ? 'active' : 'd-none'}`}
                                        id="home-tab"
                                        data-toggle="tab"
                                        href="#home"
                                        role="tab"
                                        aria-controls="home"
                                        aria-selected="true"
                                    >
                                        {i18n.t("pages.tabs.0.title")}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${currentContent?.contentType === 'exam' ? 'active' : ''}`}
                                        id="profile-tab"
                                        data-toggle="tab"
                                        href="#profile"
                                        role="tab"
                                        aria-controls="profile"
                                        aria-selected="false"
                                    >
                                        {i18n.t("pages.tabs.1.title")}
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div
                                    className={`tab-pane fade ${currentContent?.contentType === 'content' ? 'show active' : ''}`}
                                    id="home"
                                    role="tabpanel"
                                    aria-labelledby="home-tab"
                                >
                                    <div className="detail">
                                        <div className="courseList ">
                                            <div className="searchField" style={{marginBottom:"-40px"}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M20.71 19.29L17.31 15.9C18.407 14.5025 19.0022 12.7767 19 11C19 9.41775 18.5308 7.87103 17.6518 6.55544C16.7727 5.23985 15.5233 4.21447 14.0615 3.60897C12.5997 3.00347 10.9911 2.84504 9.43928 3.15372C7.88743 3.4624 6.46197 4.22433 5.34315 5.34315C4.22433 6.46197 3.4624 7.88743 3.15372 9.43928C2.84504 10.9911 3.00347 12.5997 3.60897 14.0615C4.21447 15.5233 5.23985 16.7727 6.55544 17.6518C7.87103 18.5308 9.41775 19 11 19C12.7767 19.0022 14.5025 18.407 15.9 17.31L19.29 20.71C19.3832 20.8032 19.4939 20.8772 19.6158 20.9277C19.7376 20.9781 19.8681 21.0041 20 21.0041C20.1319 21.0041 20.2624 20.9781 20.3843 20.9277C20.5061 20.8772 20.6168 20.8032 20.71 20.71C20.8032 20.6168 20.8772 20.5061 20.9277 20.3843C20.9781 20.2624 21.0041 20.1319 21.0041 20C21.0041 19.8681 20.9781 19.7376 20.9277 19.6158C20.8772 19.4939 20.8032 19.3832 20.71 19.29ZM5 11C5 9.81332 5.3519 8.65328 6.01119 7.66658C6.67047 6.67989 7.60755 5.91085 8.7039 5.45673C9.80026 5.0026 11.0067 4.88378 12.1705 5.11529C13.3344 5.3468 14.4035 5.91825 15.2426 6.75736C16.0818 7.59648 16.6532 8.66558 16.8847 9.82946C17.1162 10.9933 16.9974 12.1997 16.5433 13.2961C16.0892 14.3925 15.3201 15.3295 14.3334 15.9888C13.3467 16.6481 12.1867 17 11 17C9.4087 17 7.88258 16.3679 6.75736 15.2426C5.63214 14.1174 5 12.5913 5 11Z"
                                                        fill="#928F9A"/>
                                                </svg>
                                                <input className="search" type="text" placeholder="Ara"/>
                                            </div>
                                        </div>
                                    </div>




                                    {currentContent?.contentType === 'content' &&
                                        <Description coursesDetail={coursesDetail}/>}

                                </div>
                                <div
                                    className={`tab-pane fade ${currentContent?.contentType === 'exam' ? 'show active' : ''}`}
                                    id="profile"
                                    role="tabpanel"
                                    aria-labelledby="profile-tab"
                                >
                                    <div>
                                        <SideBar sideBarList={sideBarList} sideBarClick={sideBarClick}
                                                 resetExamResult={resetExamResult} coursesDetail={coursesDetail}
                                                 coursesDetail={coursesDetail} userAnswer={userAnswer}
                                                 setUserAnswer={setUserAnswer} currentQuestionIndex={currentQuestionIndex}
                                                 showExamResults={showExamResults} examResult={examResult}
                                                 selectedOptions={selectedOptions} handleOptionClick={handleOptionClick}
                                                 handlePreviousQuestion={handlePreviousQuestion}
                                                 handleNextQuestion={handleNextQuestion}
                                                 handleSubmitAnswers={handleSubmitAnswers} count={count}

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    }

                </div>
            </div>
        </>
    )
}

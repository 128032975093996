/*
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import trTranslation from "./locales/tr/translation.json"
import enTranslation from "./locales/en/translation.json"

import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';


const resources = {
    tr: {
        //routes:trRoutes,
        translation:trTranslation
    },
    en: {
        //routes:enRoutes,
        translation:enTranslation
    }
}


i18n
 /!*   .use(LanguageDetector)
    .use(Backend)*!/
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        whitelist: ['tr', 'en'],
        fallbackLng: 'tr',
        resources,
     detection: {
            order: ['path'],
            lookupFromPathIndex: 0,
            checkWhitelist: true
        }
    });


export default i18n;*/

import i18next from "i18next";
import { initReactI18next } from "react-i18next";
const tr = require("./locales/tr/translation.json");
const en = require("./locales/en/translation.json");

i18next.use(initReactI18next).init({
    /*  debug: false,
      initImmediate: false,
      preload: ["tr", "en"],
      fallbackLng: "en",*/
    lng: "tr",
    resources: {
        tr: { translation: tr },
        en: { translation: en },
    },
});

export default i18next;

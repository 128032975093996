import React, {createContext, useContext, useEffect, useState} from 'react';
import jwt_decode from 'jwt-decode';
import api from '../helpers/axios';

export const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [status, setStatus] = useState(true);
    const [token, setToken] = useState(null);
    const [userProfile, setUserProfile] = useState(null);
    const [profileFetched, setProfileFetched] = useState(false);
    const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);
    const [userProfileField, setUserProfileField] = useState(false);
    const [enterPassword, setEnterPassword] = useState(false);
    const [firstLogin, setFirstLogin] = useState(false);
    const [site, setSite] = useState(null);
    
    const login = async (tcNumber, password) => {
        try {
            setStatus(true);
            const response = await api.post('auth/login', JSON.stringify({
                tcNumber,
                password: password,
            }));
            
            setUser(response.data.user);
            setStatus(true);
            setIsLoginSuccessful(true);
            
            const token = response.data.token.accessToken;
            localStorage.setItem('token', token);
            setToken(token);
            
        } catch (error) {
            logOut()
            setStatus(false);
            console.error('Login error:', error);
        }
    };
    const logOut = () => {
        if (typeof window !== 'undefined') {
            localStorage.removeItem('token');
        }
        if (typeof window !== 'undefined') {
            localStorage.removeItem('user');
        }
        setUser(null);
        setToken(null);
        setUserProfile(null);
        setProfileFetched(false);
        setIsLoginSuccessful(false);
        setUserProfileField(false)
    };
    useEffect(() => {
        const storedToken = localStorage.getItem('token');

        if (storedToken) {
            const decodedUser = jwt_decode(storedToken);
            setUser(decodedUser);
            setToken(storedToken);
            const tokenExpiration = decodedUser.exp * 1000;
            const currentTime = Date.now();
            if (tokenExpiration <= currentTime) {
                logOut();
            } else {
                profile().then(result => {
                    console.log(result);
                });
            }
        } else {
            console.log('Token not found.');
        }
    }, [token]);
    useEffect(() => {
        if(!site){
            api.get(`sites`, {
                  headers: {
                      "domain": window.location.hostname,
                      "Content-Type": "application/json"
                  }
              })
              .then(res => {
                  setSite(res.data);
              })
              .catch(err => {
                  console.log('Error:', err.message);
              })
        }
    }, [site]);
	useEffect(() => {
      if(site)
		    document.title = site.name; // Başlığı burada değiştiriyoruz
	}, [site]); // Bu effect sadece ilk render'da çalışır
	
	const profile = async () => {
        if (token && !profileFetched) {
            try {
                const response = await api.get('auth/me', {
                    headers: {
                        domain: window.location.hostname,
                    },
                });
                const userProfileData = response.data;
                console.log(response.data);
                console.log('User Profile:', userProfileData);
                setUserProfileField(true)
                setUserProfile(userProfileData);
                setProfileFetched(true);
                setEnterPassword(false)
            } catch (error) {
                logOut();
                setUserProfileField(false)
                console.error('me error:', error);
            }
        }
    };
    
    
    const changePassword = async (password, rePassword) => {
        if (token) {
            try {
                const response = await api.post('change-password', JSON.stringify({
                    password,
                    rePassword
                }),{
                    headers: {
                        domain: window.location.hostname,
                    },
                });
                const userProfileData = response.data;
                console.log(response.data);
                console.log('User Profile:', userProfileData);
                setUserProfileField(true)
                setUserProfile(userProfileData);
                setProfileFetched(true);
                setEnterPassword(false)
            } catch (error) {
                logOut();
                setUserProfileField(false)
                console.error('me error:', error);
            }
        }
    };
    
    useEffect(() => {
        const storedToken = localStorage.getItem('token');

        if (storedToken) {
            const decodedUser = jwt_decode(storedToken);
            setUser(decodedUser);
            setToken(storedToken);

            profile().then(result => {

                console.log(result);
            });
        } else {
            logOut()
            console.log('Token not found.');
        }
    }, [token]);





    const forgotPassword = async (email) => {
        try {
            const resetPasswordUrl = `/v1/shared/users/forgot-password/${email}`;
            const response = await api.get(resetPasswordUrl);
            if (response.payload.success) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Şifre sıfırlama hatalı:', error);
            return false;
        }
    };
    return (
        <UserContext.Provider value={{
            user,
            token,
            login,
            logOut,
            status,
            setStatus,
            forgotPassword,
            setUserProfile,
            userProfile,
            profile, setIsLoginSuccessful, isLoginSuccessful, userProfileField, setUserProfileField,
            enterPassword, setEnterPassword,
            firstLogin, setFirstLogin,
            changePassword,
            site
        }}>
            {children}
        </UserContext.Provider>
    );
};

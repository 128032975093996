import React, {useState, useEffect} from "react";
import {BiError} from "react-icons/bi";
import "../../style/css/status.css";

const ErrorStatus = ({title, description, status}) => {
    const [isVisible, setIsVisible] = useState(status);

    useEffect(() => {
      console.log("dsfds",status)
      setIsVisible(status);
        const timeout = setTimeout(() => {
            setIsVisible(false);
        }, 3000);

        return () => clearTimeout(timeout);
    }, [status]);

    return isVisible ? (
        <div className={"status-content status-content-error  mb-4"}>
            <div className={"d-flex align-items-center"}>
                <h3 className={"status-content-title status-content-error-title"}>
                      <span className={"me-2"}>
                        <BiError size={26} color={"#FC196E"}/>
                      </span>
                    {title}
                </h3>
            </div>
            <p className={"status-content-error-description"}>
                {description}
            </p>
        </div>
    ) : null;
};

export default ErrorStatus;

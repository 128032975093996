import React from "react";
import Accordion from "react-bootstrap/Accordion";
import i18n from "../../i18n";
export default function SideBar({ sideBarList, sideBarClick, resetExamResult}) {


    return (
        <div className="lessonList">
            <Accordion defaultActiveKey="0"
                       className="course-card-user-detail-accordion " style={{marginBottom:"-20px"}}>
                {sideBarList?.contents?.map((content, key) => (

                    <Accordion.Item eventKey={content.id}
                                    className="course-card-detail-accordion-header  accordion-custom mb-4 "
                                    key={content.id}>
                        <Accordion.Header
                            className="accordion-header d-flex justify-content-between ">
                            <button className="course-card-detail-accordion-button bg-transparent border-0">
                                <div
                                    className="d-flex justify-content-between  course-card-detail-accordion-button-container">
                                    <div>
                                        <div
                                            className="card-subtitle course-card-detail-accordion-name">{content.name}</div>
                                    </div>
                                </div>
                            </button>
                        </Accordion.Header>
                        <Accordion.Body className={""}>
                            {content?.items?.map((subTags, key) => {
                                return (
                                    <div
                                        onClick={() => sideBarClick(subTags.id , "content")}
                                        className="lecture-info d-flex align-items-center justify-content-between  accor-body "
                                        style={{width: "100%"}}
                                        key={subTags.id}
                                    >
                                        <div>
                                            <img src="/images/book-icon.svg"
                                                 className="book-icon" alt="book-icon"/>
                                            <span
                                                className="card-text lecture-name ms-3">{subTags.name}</span>
                                        </div>
                                        <div>
                                            <div
                                                className="duration-page-detail ">{subTags.duration}&nbsp; {i18n.t("pages.content.duration")}</div>
                                        </div>
                                    </div>
                                )
                            })}

                            {content?.exams?.map((subTags, key) => {
                                return (
                                    <div
                                        onClick={() => {
                                            sideBarClick(subTags.id, "exam");
                                            resetExamResult();
                                        }}
                                        className="lecture-info d-flex align-items-center justify-content-between   accor-body"
                                        style={{width: "100%"}}
                                        key={subTags.id}>
                                        <div>
                                            <img src="/images/book-icon.svg"
                                                 className="book-icon"
                                                 alt="book-icon"/>
                                            <span
                                                className="card-text lecture-name ms-3">{subTags.name}</span>
                                        </div>
                                        <div>
                                            <div
                                                className="duration-page-detail ">{subTags.duration} {i18n.t("pages.content.duration")}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
            <Accordion defaultActiveKey="0"
                       className="course-card-user-detail-accordion" id={"second-accordion"}>
                {sideBarList?.exams?.map((subTags, key) => (

                    <Accordion.Item eventKey={subTags.id}
                                    className="course-card-detail-accordion-header   accordion-custom  mb-4 "
                                    key={subTags.id}>
                        <Accordion.Header
                            className="accordion-header d-flex justify-content-between ">
                            <button className="course-card-detail-accordion-button accor-course-exam-button bg-transparent border-0"  onClick={() => {
                                sideBarClick(subTags.id, "exam");
                               resetExamResult();
                            }}>
                                <div
                                    className="d-flex justify-content-between  course-card-detail-accordion-button-container">
                                    <div>
                                        <div
                                            className="card-subtitle course-card-detail-accordion-name">{subTags.name}</div>
                                    </div>
                                </div>
                            </button>
                        </Accordion.Header>
                    </Accordion.Item>
                ))}

            </Accordion>
        </div>
    );
}

import React, {useState, useEffect} from "react";
import "../../style/css/status.css";
import {SiMaildotru} from "react-icons/si";

const ResetPasswordSuccessStatus = ({title, description}) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsVisible(false);
        }, 2500);

        return () => clearTimeout(timeout);
    }, []);

    return isVisible ? (
        <div className={"forgot-password-status-content forgot-password-status-content-success"}>
            <div className={"d-flex align-items-center me-5"}>
                <h4 className={"forgot-password-status-content-title forgot-password-status-content-success-title"}>
                      <span className={"me-2"}>
                        <SiMaildotru size={26} color={"#103F9F"}/>
                      </span>
                    {title}
                </h4>
            </div>
            <p className={"forgot-password-status-content-description forgot-password-status-content-success-description "}>
                {description}
            </p>
        </div>
    ) : null;
};

export default ResetPasswordSuccessStatus;

import React from "react";
import i18n from "../../../i18n";
export default function CourseContent({coursesDetail}) {
    return (
        <React.Fragment>
                <div className="head d-none d-xl-block d-lg-block d-md-none pt-2">
                    <div className="texts">
                        <p className="Title">{coursesDetail.name}</p>
                        <p className="during">{coursesDetail.duration} {i18n.t("pages.content.duration")}</p>
                    </div>
                </div>
                <div className="vimeo">
                    <div style={{padding: "56.25% 0 0", position: "relative", paddingInline: "50px"}}>
                        <iframe
                            src={`https://player.vimeo.com/video/${coursesDetail.video}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                            frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
                            style={{position: "absolute", top: "0", left: "0", width: "100%", height: "100%"}}
                            title="A1 Vocabulary - 34"></iframe>
                    </div>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                </div>

        </React.Fragment>
    )
}

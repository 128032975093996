import React from 'react';
import { UserProvider } from '../src/context/UserContext';
import App from './App';
function Main() {
    return (
        <UserProvider >
            <App />
        </UserProvider>
    );
}
export default Main;

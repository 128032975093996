import React from 'react';
import {CircularProgress} from '@mui/material';
function Loading() {
    return (
        <div
            className="loading d-flex position-relative justify-content-center align-items-center w-100 h-100 ">
            <CircularProgress className="cross justify-content-center d-flex position-absolute"
                              style={{color: '#0642CD'}}/>
        </div>
    );
}
export default Loading;
